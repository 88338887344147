import { computed } from 'vue';

export function cartItemDto(item) {

    const id = computed(() => {
        if (item && item.id && item.id.length > 0) {
            return item.id;
        }
        return '';
    });

    const productImage = computed(() => {
        let url = '';
        
        if (item?.product?.images?.length) {
            url = item.product.images[0].url ? item.product.images[0].url : item.product.images[0] ;
        } else if (item?.product?.image?.length) {
            url = item.product.image;
        } 

        return url;
    });

    const title = computed(() => {
        if (item?.product?.title) {
            return item.product.title;
        }
        return '';
    });

    const productAttributes = computed(() => {
        if (item?.sku?.attributeValues?.length) {
            let attributes = item.sku.attributeValues.map(a => a.displayLabel).join(", ");
            return attributes;
        }
        return '';
    });

    const productSize = computed(() => {
        if (item?.sku?.attributeValues?.length) {
            let size = item.sku.attributeValues.find(a => a.optionDisplayLabel == "Size" || a.optionDisplayLabel == "size");
            return size.displayLabel;
        }
        return '';
    });

    const productColor = computed(() => {
        if (item?.sku?.attributeValues?.length) {
            let color = item.sku.attributeValues.find(a => a.optionDisplayLabel == "Color" || a.optionDisplayLabel == "color");
            return color.displayLabel;
        }
        return '';
    });

    const price = computed(() => {
        if (item.sourceType == 'bundle') {
            return item.product.skuDetails.reduce((sum,c) => sum + c.salesPrice, 0)
        }
        
        if(item?.sku?.discountedSalesPrice > 0){
            return (Number(item.sku.discountedSalesPrice)).toFixed(2);
        }else{
            return (Number(item.sku.salesPrice)).toFixed(2);
        }
    });

    const quantity = computed(() => {
        if(item?.quantity) {
            return item.quantity;
        }
        return 0;
    });

    const subtotal = computed(() => {
        let subTotal = Number(price.value) * Number(quantity.value);
        return (Number(subTotal)).toFixed(2);
    });


    return {
        id,
        productImage,
        title,
        productAttributes,
        productSize,
        productColor,
        price,
        quantity,
        subtotal,
    };
}
