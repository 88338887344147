import { default as aboutDe3vOKefZFMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/about.vue?macro=true";
import { default as cartB1u8aatlCSMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/cart.vue?macro=true";
import { default as checkoutwFEG6G0t94Meta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/checkout.vue?macro=true";
import { default as coming_45soonEkM9QOiPNQMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/coming-soon.vue?macro=true";
import { default as contactahjxiNPuh1Meta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/contact.vue?macro=true";
import { default as detailsCiYOdbtFPmMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/details.vue?macro=true";
import { default as indexCmvtaYoqJOMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/index.vue?macro=true";
import { default as new_45arrivalBjhUIQV7mVMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/new-arrival.vue?macro=true";
import { default as ordersNfsOC48yzOMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/orders.vue?macro=true";
import { default as outletspI9ts1gIcAMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/outlets.vue?macro=true";
import { default as canceljOsUztbCNIMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/payments/response/cancel.vue?macro=true";
import { default as failedmuvTdbwvTaMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/payments/response/failed.vue?macro=true";
import { default as laterlSjkKhdC87Meta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/payments/response/later.vue?macro=true";
import { default as success6Rr9V5cJsHMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/payments/response/success.vue?macro=true";
import { default as privacy_45policya3luPjPly5Meta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/privacy-policy.vue?macro=true";
import { default as profileuy6w2hVStlMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/profile.vue?macro=true";
import { default as returnlp8wn18fCUMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/return.vue?macro=true";
import { default as saleslETj5zgFOfMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/sales.vue?macro=true";
import { default as shippingodNMhrKVcVMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/shipping.vue?macro=true";
import { default as shop_32copy9yGmmf0ynIMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/shop copy.vue?macro=true";
import { default as shopcQRDw9BPakMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/shop.vue?macro=true";
import { default as sofa_453duXSf9AKofKMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/sofa-3d.vue?macro=true";
import { default as terms_45conditionsY7Q01zzUZqMeta } from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: aboutDe3vOKefZFMeta?.name ?? "about",
    path: aboutDe3vOKefZFMeta?.path ?? "/about",
    meta: aboutDe3vOKefZFMeta || {},
    alias: aboutDe3vOKefZFMeta?.alias || [],
    redirect: aboutDe3vOKefZFMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/about.vue").then(m => m.default || m)
  },
  {
    name: cartB1u8aatlCSMeta?.name ?? "cart",
    path: cartB1u8aatlCSMeta?.path ?? "/cart",
    meta: cartB1u8aatlCSMeta || {},
    alias: cartB1u8aatlCSMeta?.alias || [],
    redirect: cartB1u8aatlCSMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutwFEG6G0t94Meta?.name ?? "checkout",
    path: checkoutwFEG6G0t94Meta?.path ?? "/checkout",
    meta: checkoutwFEG6G0t94Meta || {},
    alias: checkoutwFEG6G0t94Meta?.alias || [],
    redirect: checkoutwFEG6G0t94Meta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: coming_45soonEkM9QOiPNQMeta?.name ?? "coming-soon",
    path: coming_45soonEkM9QOiPNQMeta?.path ?? "/coming-soon",
    meta: coming_45soonEkM9QOiPNQMeta || {},
    alias: coming_45soonEkM9QOiPNQMeta?.alias || [],
    redirect: coming_45soonEkM9QOiPNQMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/coming-soon.vue").then(m => m.default || m)
  },
  {
    name: contactahjxiNPuh1Meta?.name ?? "contact",
    path: contactahjxiNPuh1Meta?.path ?? "/contact",
    meta: contactahjxiNPuh1Meta || {},
    alias: contactahjxiNPuh1Meta?.alias || [],
    redirect: contactahjxiNPuh1Meta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: detailsCiYOdbtFPmMeta?.name ?? "details",
    path: detailsCiYOdbtFPmMeta?.path ?? "/details",
    meta: detailsCiYOdbtFPmMeta || {},
    alias: detailsCiYOdbtFPmMeta?.alias || [],
    redirect: detailsCiYOdbtFPmMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/details.vue").then(m => m.default || m)
  },
  {
    name: indexCmvtaYoqJOMeta?.name ?? "index",
    path: indexCmvtaYoqJOMeta?.path ?? "/",
    meta: indexCmvtaYoqJOMeta || {},
    alias: indexCmvtaYoqJOMeta?.alias || [],
    redirect: indexCmvtaYoqJOMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/index.vue").then(m => m.default || m)
  },
  {
    name: new_45arrivalBjhUIQV7mVMeta?.name ?? "new-arrival",
    path: new_45arrivalBjhUIQV7mVMeta?.path ?? "/new-arrival",
    meta: new_45arrivalBjhUIQV7mVMeta || {},
    alias: new_45arrivalBjhUIQV7mVMeta?.alias || [],
    redirect: new_45arrivalBjhUIQV7mVMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/new-arrival.vue").then(m => m.default || m)
  },
  {
    name: ordersNfsOC48yzOMeta?.name ?? "orders",
    path: ordersNfsOC48yzOMeta?.path ?? "/orders",
    meta: ordersNfsOC48yzOMeta || {},
    alias: ordersNfsOC48yzOMeta?.alias || [],
    redirect: ordersNfsOC48yzOMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: outletspI9ts1gIcAMeta?.name ?? "outlets",
    path: outletspI9ts1gIcAMeta?.path ?? "/outlets",
    meta: outletspI9ts1gIcAMeta || {},
    alias: outletspI9ts1gIcAMeta?.alias || [],
    redirect: outletspI9ts1gIcAMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/outlets.vue").then(m => m.default || m)
  },
  {
    name: canceljOsUztbCNIMeta?.name ?? "payments-response-cancel",
    path: canceljOsUztbCNIMeta?.path ?? "/payments/response/cancel",
    meta: canceljOsUztbCNIMeta || {},
    alias: canceljOsUztbCNIMeta?.alias || [],
    redirect: canceljOsUztbCNIMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/payments/response/cancel.vue").then(m => m.default || m)
  },
  {
    name: failedmuvTdbwvTaMeta?.name ?? "payments-response-failed",
    path: failedmuvTdbwvTaMeta?.path ?? "/payments/response/failed",
    meta: failedmuvTdbwvTaMeta || {},
    alias: failedmuvTdbwvTaMeta?.alias || [],
    redirect: failedmuvTdbwvTaMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/payments/response/failed.vue").then(m => m.default || m)
  },
  {
    name: laterlSjkKhdC87Meta?.name ?? "payments-response-later",
    path: laterlSjkKhdC87Meta?.path ?? "/payments/response/later",
    meta: laterlSjkKhdC87Meta || {},
    alias: laterlSjkKhdC87Meta?.alias || [],
    redirect: laterlSjkKhdC87Meta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/payments/response/later.vue").then(m => m.default || m)
  },
  {
    name: success6Rr9V5cJsHMeta?.name ?? "payments-response-success",
    path: success6Rr9V5cJsHMeta?.path ?? "/payments/response/success",
    meta: success6Rr9V5cJsHMeta || {},
    alias: success6Rr9V5cJsHMeta?.alias || [],
    redirect: success6Rr9V5cJsHMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/payments/response/success.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policya3luPjPly5Meta?.name ?? "privacy-policy",
    path: privacy_45policya3luPjPly5Meta?.path ?? "/privacy-policy",
    meta: privacy_45policya3luPjPly5Meta || {},
    alias: privacy_45policya3luPjPly5Meta?.alias || [],
    redirect: privacy_45policya3luPjPly5Meta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profileuy6w2hVStlMeta?.name ?? "profile",
    path: profileuy6w2hVStlMeta?.path ?? "/profile",
    meta: profileuy6w2hVStlMeta || {},
    alias: profileuy6w2hVStlMeta?.alias || [],
    redirect: profileuy6w2hVStlMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: returnlp8wn18fCUMeta?.name ?? "return",
    path: returnlp8wn18fCUMeta?.path ?? "/return",
    meta: returnlp8wn18fCUMeta || {},
    alias: returnlp8wn18fCUMeta?.alias || [],
    redirect: returnlp8wn18fCUMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/return.vue").then(m => m.default || m)
  },
  {
    name: saleslETj5zgFOfMeta?.name ?? "sales",
    path: saleslETj5zgFOfMeta?.path ?? "/sales",
    meta: saleslETj5zgFOfMeta || {},
    alias: saleslETj5zgFOfMeta?.alias || [],
    redirect: saleslETj5zgFOfMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/sales.vue").then(m => m.default || m)
  },
  {
    name: shippingodNMhrKVcVMeta?.name ?? "shipping",
    path: shippingodNMhrKVcVMeta?.path ?? "/shipping",
    meta: shippingodNMhrKVcVMeta || {},
    alias: shippingodNMhrKVcVMeta?.alias || [],
    redirect: shippingodNMhrKVcVMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/shipping.vue").then(m => m.default || m)
  },
  {
    name: shop_32copy9yGmmf0ynIMeta?.name ?? "shop copy",
    path: shop_32copy9yGmmf0ynIMeta?.path ?? "/shop%20copy",
    meta: shop_32copy9yGmmf0ynIMeta || {},
    alias: shop_32copy9yGmmf0ynIMeta?.alias || [],
    redirect: shop_32copy9yGmmf0ynIMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/shop copy.vue").then(m => m.default || m)
  },
  {
    name: shopcQRDw9BPakMeta?.name ?? "shop",
    path: shopcQRDw9BPakMeta?.path ?? "/shop",
    meta: shopcQRDw9BPakMeta || {},
    alias: shopcQRDw9BPakMeta?.alias || [],
    redirect: shopcQRDw9BPakMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: sofa_453duXSf9AKofKMeta?.name ?? "sofa-3d",
    path: sofa_453duXSf9AKofKMeta?.path ?? "/sofa-3d",
    meta: sofa_453duXSf9AKofKMeta || {},
    alias: sofa_453duXSf9AKofKMeta?.alias || [],
    redirect: sofa_453duXSf9AKofKMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/sofa-3d.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsY7Q01zzUZqMeta?.name ?? "terms-conditions",
    path: terms_45conditionsY7Q01zzUZqMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsY7Q01zzUZqMeta || {},
    alias: terms_45conditionsY7Q01zzUZqMeta?.alias || [],
    redirect: terms_45conditionsY7Q01zzUZqMeta?.redirect || undefined,
    component: () => import("C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/pages/terms-conditions.vue").then(m => m.default || m)
  }
]