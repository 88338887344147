import { ref } from "vue";
import { defineStore } from "pinia";
import { cmsClient, wishlistsClient } from '@/assets/apis/config';

export const useWishlistStore = defineStore("wishlistStore", () => {

    /** STATES */
    const wishList = ref([]);
    const totalCount = ref(0);
    const isLoading = ref(false);
    // const TIMESTAMP = Date.now();

    /** ACTIONS */
    const fetchRequestItem = () => {
        let obj = {
            size: 10,
            page: 1,
            textParams: [
                {
                    field: "wishlistId",
                    value: "",
                    matchType: "EXACT"
                }
            ],
            sortParams: [
                {
                    field: "dateCreated",
                    type: "DESCENDING"
                }
            ]
        }
        // console.dir({obj});
        wishlistsClient.search(obj).then((res) => {
            if (!res.networkError) {
                if (res.status == 200) {
                    // console.dir({wishListResponse: res});
                    wishList.value = res.body.list;
                    totalCount.value = res.body.total;
                }
            }
            isLoading.value = false;
        });
    };

    const updateList = (obj) => {
        if (process.client) {
            let profile = JSON.parse(localStorage.getItem("profile"));
            let userId = profile && profile.userId && profile.userId.length > 0 ? profile.userId : "";
            // console.dir({profile});
            // console.dir({obj});
            if (obj.itemId) {
                obj.userId = userId;
                let hasItem = wishList.value.filter(i => i.itemId == obj.itemId && i.userId == userId);
                if (!hasItem.length) {
                    wishList.value.push(obj.userId);
                    totalCount.value += 1;
                    isExists(obj.itemId, obj.skuCode);
                }
            }   
        }
        // console.dir({wishList: wishList.value});
    };

    const removeItem = (obj) => {

        if (obj.itemId && obj.wishlistId) {
            obj.userId = userId;
            let index = wishList.value.findIndex(i => i.itemId == obj.itemId && i.userId == obj.userId);
            if (index > -1) {
                wishList.value = wishList.value.splice(index, 1);
            }
        }
    };

    const isExists = (id, sku) => {
        if (process.client) {
            let exists = false;
            let profile = JSON.parse(localStorage.getItem("profile"));
            let userId = profile && profile.userId && profile.userId.length > 0 ? profile.userId : "";
            if (wishList.value && wishList.value.length > 0) {
                if (id && id.length > 0) {
                    let hasItem = wishList.value.filter(i => i.itemId == id && i.skuCode == sku && i.userId == userId);
                    if (hasItem.length > 0) {
                        exists = true;
                    }
                }
            }
            
            return exists;
        }
        // console.dir({exists});
    };

    const isItemExists = (id) => {
        if (process.client) {
            let exists = false;
            let profile = JSON.parse(localStorage.getItem("profile"));
            let userId = profile && profile.userId && profile.userId.length > 0 ? profile.userId : "";
            if (wishList.value && wishList.value.length > 0) {
                if (id && id.length > 0) {
                    let hasItem = wishList.value.filter(i => i.itemId == id && i.userId == userId);
                    if (hasItem.length > 0) {
                        exists = true;
                    }
                }
            }
            // console.dir({exists});
            return exists;
        }
    };

    const findWishlistItem = (id) => {
        if (process.client) {
            let exists = null;
            let profile = JSON.parse(localStorage.getItem("profile"));
            let userId = profile && profile.userId && profile.userId.length > 0 ? profile.userId : "";
            if (wishList.value && wishList.value.length > 0) {
                if (id && id.length > 0) {
                    let hasItem = wishList.value.filter(i => i.itemId == id && i.userId == userId);
                    if (hasItem.length > 0) {
                        exists = hasItem[0];
                    }
                }
            }
            // console.dir({exists});
            return exists;
        }
    };

    const initialize = () => {
        isLoading.value = true;
        fetchRequestItem();
    }

    /** RETURN */
    return { 
        wishList,
        totalCount,
        isLoading,
        updateList,
        isExists,
        isItemExists,
        findWishlistItem,
        removeItem,
        initialize, 
    };

});
