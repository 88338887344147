<template>
    <div class="container-fluid bg-footer">
        <div class="d-flex justify-content-between flex-wrap px-0 px-lg-5 px-md-5 py-4 desktop">
            <div class="mt-sm-3">
                <div class="mb-2">
                    <div class="fw-nm-bold mb-1">ABOUT US</div>
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="/contact" @click.prevent="navigateTo('contact')">Contact Us</a>
                    </div>
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent="navigateTo('about')">
                            Our Story
                        </a>
                    </div>
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent="navigateTo('coming-soon')">Company Profile</a>
                    </div>
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent="navigateTo('coming-soon')">
                            License & CertificatedS
                        </a>
                    </div>
                   
                </div>
            </div>

            <div class="mt-sm-3">
                <div class="mb-2">
                    <div class="fw-nm-bold mb-1">OUR COMPANY</div>
                    
                    
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent="navigateTo('shop')">Product Catalog</a>
                    </div>

                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent="navigateTo('outlets')">
                            Our Showroom
                        </a>
                    </div>
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent="navigateTo('coming-soon')">Career</a>
                    </div>
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent="navigateTo('coming-soon')">Press</a>
                    </div>
                </div>
            </div>

            <div class="mt-sm-3">
                <div class="mb-2">
                    <div class="fw-nm-bold mb-1">CUSTOMER SERVICE</div>
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a
                            href="#"
                            @click.prevent="navigateTo('terms-conditions')"
                        >
                            Terms & Conditions
                        </a>
                    </div>
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent="navigateTo('privacy-policy')">
                            Privacy & Policy
                        </a>
                    </div>
                    
                    
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="/return" @click.prevent="navigateTo('return')">
                            Return & Refund Policy
                        </a>
                    </div>
                    <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent="navigateTo('shipping')">Delivery and Shipping Information</a>
                    </div>
                    <!-- <div class="fw-light fs-7 my-1 fw-nm-bold">
                        <a href="#" @click.prevent>Furniture Care</a>
                    </div> -->
                </div>
            </div>
            <div class="mt-sm-3">
                <div class="">
                    <div class="fw-nm-bold mb-1">FOLLOW US</div>
                    <div class="social-icons d-flex" style="margin: 12px 0 !important">
                        <a href="https://www.facebook.com/akhtarfurnishers" target="_blank"
                            class="d-flex justify-content-center align-items-center facebook me-2 rounded-circle bg-dark">
                            <i class="fa fa-facebook"></i>
                        </a>
                        <a href="https://twitter.com/AkhtarFurn87294" target="_blank"
                            class="d-flex justify-content-center align-items-center twitter mx-2 rounded-circle bg-dark">
                            <i class="fa fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/akhtar.officialbd/" target="_blank"
                            class="d-flex justify-content-center align-items-center instagram mx-2 rounded-circle bg-dark">
                            <i class="fa fa-instagram fa-lg"></i>
                        </a>
                        <a href="whatsapp://send?text=Welcome&phone=+8801847004700" target="_blank"
                            class="d-flex justify-content-center align-items-center whatsapp mx-2 rounded-circle bg-dark">
                            <i class="fa fa-whatsapp fa-lg"></i>
                        </a>
                        <div></div>
                    </div>
                </div>
                <div class="follow-section">
                    <div class="hotline-section">
                        <div class="d-flex align-items-center">
                            <div class="pe-2">
                                <i class="fa fa-headphones"></i>
                            </div>
                            <div><a href="tel:16770" class="headphone-span">16770</a>,</div> 
                            <div><a href="tel:+8809611116770" class="headphone-span">+88 09611116770</a></div> 
                        </div>

                        <div class="d-flex align-items-center cursor-pointer">
                            <div class="pe-2">
                                <i class="fa fa-envelope"></i>
                            </div>
                            <div>
                                <span class="envelope-section" @click.prevent="sendMail()">crm@akhtar.com.bd</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-sm-3">
                <div class="">
                    <div class="fw-nm-bold mb-1">WE ACCEPT</div>
                    <div class="payment-icons">
                        <div class="accept" style="
                            display: flex;
                            flex-wrap: wrap;
                            margin: 12px 0;
                            /* margin-bottom: 5px; */
                        ">
                            <img src="/images/footer/AE.svg" class="me-1" style="
                                height: 40px;
                                background-color: #fff;
                            " alt="" />
                            <img src="/images/footer/master.svg" class="mx-1" style="
                                height: 40px;
                                background-color: #fff;
                            " alt="" />
                            <img src="/images/footer/visa.svg" class="mx-1" style="
                                height: 40px;
                                background-color: #fff;
                            " alt="" />
                            <img
                                src="/images/footer/bkash.svg"
                                class="ms-1" style="
                                    height: 40px;
                                    background-color: #fff;
                                "
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="social-icons ssl-commerz p-0">
                        <div class="accept">
                            <img src="/images/footer/ssl.svg" style="
                                    height: 35px !important;
                                    background-color: #fff;
                                " alt="ssl" />
                        </div>
                    </div>
                    <div
                        class="social-icons"
                        style="padding: 0px !important; font-size: 12px"
                    >
                        TIN : 125591546751
                    </div>
                    <div
                        class="social-icons"
                        style="padding: 0px !important; font-size: 12px"
                    >
                        BIN : 18151044677
                    </div>
                    <div
                        class="social-icons"
                        style="padding: 0px !important; font-size: 12px"
                    >
                        Trade License : 23356824319000155
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column justify-content-between align-items-center flex-wrap px-0 px-lg-5 px-md-5 py-4 mobile">
           
            <div class="accordion accordion-flush">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <div class="fw-nm-bold mb-1 text-center">ABOUT US</div>
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample">
                        <div class=" accordion-body d-flex flex-column align-items-center">
                            <div class="">
                                <a href="/contact" @click.prevent="navigateTo('contact')">Contact Us</a>
                            </div>
                            <div>
                                <a href="#" @click.prevent="navigateTo('about')">
                                    Our Story
                                </a>
                            </div>
                            <div>
                                <a href="#" @click.prevent="navigateTo('coming-soon')">Company Profile</a>
                            </div>
                            <div>
                                <a href="#" @click.prevent="navigateTo('coming-soon')">
                                    License & CertificatedS
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="accordion-item mt-2">
                    <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <div class="fw-nm-bold mb-1 text-center">OUR COMPANY</div>
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample">
                        <div class=" accordion-body d-flex flex-column align-items-center">
                            
                            <div>
                                <a href="#" @click.prevent="navigateTo('shop')">Product Catalog</a>
                            </div>
                            <div>
                                <a href="#" @click.prevent="navigateTo('outlets')">
                                    Our Showroom
                                </a>
                            </div>
                            <div>
                                <a href="#" @click.prevent="navigateTo('coming-soon')">Career</a>
                            </div>
                            <div>
                                <a href="#" @click.prevent="navigateTo('coming-soon')">Press</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item mt-2">
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <div class="fw-nm-bold mb-1 text-center">CUSTOMER SERVICE</div>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample">
                        <div class=" accordion-body d-flex flex-column align-items-center">
                            <div>
                                <a
                                    href="#"
                                    @click.prevent="navigateTo('terms-conditions')"
                                >
                                    Terms & Conditions
                                </a>
                            </div>
                            <div>
                                <a href="#" @click.prevent="navigateTo('privacy-policy')">
                                    Privacy & Policy
                                </a>
                            </div>
                            
                            <div>
                                <a href="/return" @click.prevent="navigateTo('return')">
                                    Return & Refund Policy
                                </a>
                            </div>
                            <div>
                                <a href="#" @click.prevent="navigateTo('shipping')">Delivery and Shipping Information</a>
                            </div>
                            <!-- <div>
                                <a href="#" @click.prevent>Furniture Care</a>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="mt-sm-3 mt-2">
                    <div class="">
                        <div class="fw-nm-bold mb-1 text-center">FOLLOW US</div>
                        <div class="social-icons d-flex justify-content-center align-items-center" style="margin: 12px 0 !important">
                            <a href="https://www.facebook.com/akhtarfurnishers" target="_blank"
                                class="d-flex justify-content-center align-items-center facebook me-3 rounded-circle bg-dark">
                                <i class="fa fa-facebook"></i>
                            </a>
                            <a href="https://twitter.com/AkhtarFurn87294" target="_blank"
                                class="d-flex justify-content-center align-items-center twitter mx-3 rounded-circle bg-dark">
                                <i class="fa fa-twitter"></i>
                            </a>
                            <a href="https://www.instagram.com/akhtar.officialbd/" target="_blank"
                                class="d-flex justify-content-center align-items-center instagram mx-3 rounded-circle bg-dark">
                                <i class="fa fa-instagram fa-lg"></i>
                            </a>
                            <a href="whatsapp://send?text=Welcome&phone=+8801847004700" target="_blank"
                                class="d-flex justify-content-center align-items-center whatsapp mx-3 rounded-circle bg-dark">
                                <i class="fa fa-whatsapp fa-lg"></i>
                            </a>
                            <div></div>
                        </div>
                    </div>
                    <!-- <div class="follow-section">
                        <div class="hotline-section">
                            <div class="d-flex align-items-center">
                                <div class="pe-2">
                                    <i class="fa fa-headphones"></i>
                                </div>
                                <div><a href="tel:16770" class="headphone-span">16770</a>,</div> 
                                <div><a href="tel:+8809611116770" class="headphone-span">+88 09611116770</a></div> 
                            </div>

                            <div class="d-flex align-items-center">
                                <div class="pe-2">
                                    <i class="fa fa-envelope"></i>
                                </div>
                                <div>
                                    <span class="envelope-section" @click.prevent="sendMail()">crm@akhtar.com.bd</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="mt-sm-3">
                <div class="">
                    <div class="fw-nm-bold my-2 text-center">WE ACCEPT</div>
                    <div class="payment-icons">
                        <div class="accept" style="
                            display: flex;
                            flex-wrap: wrap;
                            margin: 12px 0;
                            /* margin-bottom: 5px; */
                        ">
                            <img src="/images/A_E-Logo.jpg" class="me-2" style="
                                height: 30px !important;
                                background-color: #fff;
                            " alt="" />
                            <img src="/images/MC-Logo.png" class="mx-2" style="
                                height: 30px !important;
                                background-color: #fff;
                            " alt="" />
                            <img src="/images/Visa-Logo.png" class="mx-2" style="
                                height: 30px !important;
                                background-color: #fff;
                            " alt="" />
                            <img
                                src="/images/BKash-Logo.jpg"
                                class="ms-2" style="
                                    height: 30px !important;
                                    background-color: #fff;
                                "
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="social-icons ssl-commerz p-0">
                        <div class="accept d-flex justify-content-center align-items-center">
                            <img src="/images/4ssl.png" alt="ssl" style="
                                    height: 30px !important;
                                    background-color: #fff;
                                "/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-center align-items-center">
                <!-- <img src="/images/ssl-full.png" class="w-80" alt="sslcommerz"/> -->
                <img src="/images/sssl-single.jpeg" class="w-100" alt="sslcommerz"/>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center p-3 bg-footer">
            <div class="fs-7 fw-bold text-default ">
                @ {{ year }} AKHTAR
            </div>
        </div>
        
        <CommonsModalBaseModal 
            :show="mailUsModalVisibility" 
            @close="mailUsModalVisibility = false" 
            :modalFooter="false" maxWidth="60%" 
            bodyStyle="padding: 0 !important"
            title=""
            class="px-2"
        >
            <MailUs @close="mailUsModalVisibility = false" />
        </CommonsModalBaseModal>
    </div>
</template>

<script setup>
import MailUs from "../nav/MailUs.vue";

const router = useRouter();

const signUpModalVisibility = ref(false);
const mailUsModalVisibility = ref(false);
const year = new Date().getFullYear();

const navigateTo = async (name) => {
    if (name.length > 0) {
        router.push({
            name: name,
        });
    }
};

const sendMail = async () => {
    mailUsModalVisibility.value = !mailUsModalVisibility.value;
};

const call = () => {
    if (process.client) {
        document.getElementById("call").click();
        
    }
};
</script>

<style lang="scss">
.input-container {
    // height: 40px;
    // border: none;

    &::placeholder {
        // font-size: 12px;
        // font-weight: 450;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.social-icons {
    a {
        width: 30px;
        height: 30px;

        i {
            color: #fff;
        }
    }

    .facebook {
        &:hover {
            background-color: #0778e8 !important;
        }

    }

    .twitter {

        &:hover {
            background-color: #5dbced !important;
        }

    }

    .instagram {

        &:hover {
            background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%) !important;
        }
    }

    .whatsapp {

        &:hover {
            background-color: #25D366 !important;
        }
    }
}
.payment-icons {
    
    .accept {
        img {
            cursor: pointer;
            filter: grayscale(100%);
            height: 35px !important;
            &:hover {
                filter: none;
            }
        }
    }
}

.ssl-commerz {
    
    .accept {
        img {
            cursor: pointer;
            filter: grayscale(100%);
            height: 35px !important;
            &:hover {
                filter: none;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .input-container {
        // border-radius: 0;

        &::placeholder {
            // font-size: 14px;
        }
    }

    .payment-icons {

        .accept {
            img {
                height: 45px !important;
            }
        }
    }
}
.desktop {
    display: flex;
}
.mobile {
    display: none !important;
}
.bg-footer {
    .mobile {
        .accordion-button {
            background-color: #FBFBF9 !important;
        }
        .accordion-collapse {
            background-color: #FBFBF9 !important;
        }
    }
}
@media screen and (max-width: 1023px) {
    .desktop {
        display: none !important;
    }
    .mobile {
        display: flex !important;
    } 
}
</style>
