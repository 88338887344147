<template>
    <div class="navbar-container-mobile" style="z-index: 3025;">
        <div class="navbar-top-section px-3 py-2">
            <div class="row">
                <div class="col-4 col-lg-4 d-flex justify-content-start align-items-center">
                    <nav class="d-flex align-items-center me-4" id="NavBar">
                        <button class="navbar-toggler" :class="{'collapsed': !mobileNavVisibility}" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            :aria-expanded="mobileNavVisibility" aria-label="Toggle navigation"
                            @click.prevent="showMobileNav">
                            <span class="icon-bar top-bar"></span>
                            <span class="icon-bar middle-bar"></span>
                            <span class="icon-bar bottom-bar"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent"></div>
                    </nav>
                    <!-- <SearchBar @hideNav="$emit('hideNav')" /> -->
                </div>

                <div class="col-4 col-lg-4 d-flex justify-content-center align-items-center">
                    <div class="logo-section">
                        <RouterLink to="/" @click.prevent="gotoIndexPage()">
                            <img class="logo-img" src="/logo/logo.png" alt="Logo" />
                        </RouterLink>
                    </div>
                </div>
                <div class="col-4 col-lg-4 d-flex justify-content-end align-items-center">
                    <CommonsNavComponentCartSectionMob @hideNav="mobileNavVisibility = false"/>
                </div>
            </div>
        </div>

        <div class="mobile-nav-sidebar" v-show="mobileNavVisibility" id="navLink">
        <div style="padding: 0 25px;" class="row">
            <!-- <CartSectionMobile @hideNav="showMobileNav"/> -->
            <div class="col-3 pt-2">
                <!-- <CartSection /> -->
                <CommonsNavCurrencyDropdown />
            </div>
            <div class="col-9 p-0">
                <SearchBar @hideNav="mobileNavVisibility = false" />
            </div>
        </div>
        <NavbarItemsMobile style="margin-top: 15px;" @hideNav="showMobileNav"/>
        <!-- <NavbarItems style="margin-top: 15px;" @hideNav="showMobileNav"/> -->

        <!-- <div class="call-us" >
            <p style="font-size: 16px; margin-bottom: 0px;">
                Call Us
            </p>
        </div>
      
        <div class="mobile-hotline-section">
            <div>
                <a class="headphone" href="tel:16770">
                    <i class="fa fa-headphones"></i>
                    <span class="headphone-span">16770</span>
                </a>
                <div class="envelope">
                    <i class="fa fa-envelope"></i>
                    <span class="envelope-span">crm@akhtar.com.bd</span>
                </div>
            </div>
        </div> -->
    </div>
    </div>
</template>

<script setup>
import CartSection from "./CartSection.vue";
// import NavbarItems from "./NavbarItems.vue";
import NavbarItemsMobile from "./NavbarItemsMobile.vue";

import NavbarItemsV2 from "./NavbarItemsV2.vue";
import SearchBar from "./SearchBar.vue";
import CartSectionMobile from "./CartSectionMobile.vue";    

const router = useRouter();

const showSearchCart = ref(false);
const gotoIndexPage = async () => {
    router.push({
        name: "index",
    })
    mobileNavVisibility.value = false;
}

const mobileNavVisibility = ref(false);

const showMobileNav = async () => {
    mobileNavVisibility.value = !mobileNavVisibility.value;
    if (process.client) {
        const body = document.querySelector("body");
        if (mobileNavVisibility.value)
            body.style.setProperty("overflow", "hidden")
        else
            body.style.removeProperty("overflow")
    }
};
</script>

<style lang="scss">
@media screen and (min-width: 1200px) {
    .navbar-container-mobile {
        display: none;
    }
}

.navbar-toggler span {
    display: block;
    background-color: #4f4f4f;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;
}

.navbar-toggler span:nth-child(1) {
    transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(2) {
    opacity: 1;
}

.navbar-toggler span:nth-child(3) {
    transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(1) {
    margin-top: 0.3em;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    transform: translate(15%, 33%) rotate(-45deg);
}
.navbar-toggler span {
  height: 2px;
  width: 20px;
}
</style>
