import { ref } from "vue";
import { defineStore } from "pinia";
import skuUtils from "@/internals/sku-utils";
import { useGlobalDiscountStore } from "@/stores/global-discount-store";

export const useCartStore = defineStore("cartStore", () => {
    const globalDiscountStore = useGlobalDiscountStore();
    const items = ref([]);
    const quantity = ref(0);
    const total = ref(0);
    const subTotal = ref(0);
    const discount = ref(0);
    const coupon = ref(0);

    // add to cart item
    function addToCart(payload) {
        // console.log("payload");
        // console.log(payload);

        let cartItem = {};
        // if (items.value.length) {
        //     if (payload?.sourceType == 'bundle') {
        //         cartItem = items.value.find((item) => item.product.code === payload.product.code);
        //     } else
        // }
        cartItem = items.value.find(
            (item) => item?.sku?.code === payload.sku.code
        );
        // console.log("cartItem");
        // console.log(cartItem);
        if (cartItem) {
            cartItem.quantity += payload.quantity;

            // if (payload?.sourceType == 'bundle') {
            //     cartItem.subTotal = Number(payload.salesPrice) * cartItem.quantity;
            //     total.value += Number(payload.salesPrice) * payload.quantity;
            // } else {
            // ca?.price Number(skuUtils.extractPrice(payload.sku)) * cartItem.quantity;
            // total.value +=
            //     Number(skuUtils.extractPrice(payload.sku)) * payload.quantity;
            // }
            cartItem.subTotal =
                globalDiscount(payload.sku, payload.product)?.price *
                cartItem.quantity;
            total.value +=
                globalDiscount(payload.sku, payload.product)?.price * payload.quantity;
            subTotal.value +=
                globalDiscount(payload.sku, payload.product)?.salesPrice * payload.quantity;
            discount.value +=
                globalDiscount(payload.sku, payload.product)?.discount * payload.quantity;

            quantity.value += payload.quantity;
        } else {
            let item = {};

            item.product = payload.product;
            item.sku = payload.sku;
            item.quantity = payload.quantity;
            // item.sourceType = payload?.sourceType == 'bundle' ? 'bundle' : 'item';

            // if (payload?.sourceType == 'bundle') {
            //     item.subTotal = Number(payload.salesPrice) * item.quantity;
            //     total.value += Number(payload.salesPrice) * payload.quantity;
            // } else {
            // item.subTotal =
            //     Number(skuUtils.extractPrice(payload.sku)) * item.quantity;
            // total.value +=
            //     Number(skuUtils.extractPrice(payload.sku)) * payload.quantity;
            // }
            item.subTotal =
                globalDiscount(payload.sku, payload.product)?.price * item.quantity;
            subTotal.value +=
                globalDiscount(payload.sku, payload.product)?.salesPrice * item.quantity;
            total.value +=
                globalDiscount(payload.sku, payload.product)?.price * item.quantity;
            discount.value +=
                globalDiscount(payload.sku, payload.product)?.discount * item.quantity;
            // console.log("~~~item");
            // console.log(item);
            items.value.push(item);

            quantity.value += payload.quantity;
        }

        // console.log({
        //     items: items.value,
        //     quantity: quantity.value,
        //     total: total.value,
        // });
        if (process.client) {
            localStorage.setItem(
                "cart",
                JSON.stringify({
                    items: items.value,
                    quantity: quantity.value,
                    total: total.value,
                    subTotal: subTotal.value,
                    discount: discount.value,
                })
            );
        }
    }

    // increase item quantity
    function increase(payload) {
        const index = items.value.indexOf(payload);
        if (index != -1) {
            let cartItem = items.value[index];
            cartItem.quantity++;
            if (payload?.sourceType == "bundle") {
                cartItem.subTotal =
                    Number(payload.salesPrice) * cartItem.quantity;
                total.value += Number(payload.salesPrice);
                subTotal.value +=Number(payload.salesPrice);
                //             cartItem.subTotal =
                //     globalDiscount(payload.sku, payload.product) * cartItem.quantity;

                //   total.value += globalDiscount(cartItem.sku, payload.product);
            } else {
                // cartItem.subTotal =
                //     Number(skuUtils.extractPrice(payload.sku)) *
                //     cartItem.quantity;
                // total.value += Number(skuUtils.extractPrice(cartItem.sku));

                cartItem.subTotal =
                    globalDiscount(payload.sku, payload.product)?.price *
                    cartItem.quantity;
                subTotal.value += globalDiscount(payload.sku, payload.product)?.salesPrice;
                total.value += globalDiscount(cartItem.sku, payload.product)?.price;
                discount.value += globalDiscount(cartItem.sku, payload.product)?.discount;
            }

            quantity.value++;
        }

        localStorage.setItem(
            "cart",
            JSON.stringify({
                items: items.value,
                quantity: quantity.value,
                total: total.value,
                subTotal: subTotal.value,
                discount: discount.value,
            })
        );
    }

    // decrease items quantity
    function decrease(payload) {
        const index = items.value.indexOf(payload);
        if (index != -1) {
            let cartItem = items.value[index];
            if (cartItem.quantity > 1) {
                cartItem.quantity--;
                if (payload?.sourceType == "bundle") {
                    cartItem.subTotal =
                        Number(payload.salesPrice) * cartItem.quantity;
                    total.value -= Number(payload.salesPrice);
                    subTotal.value -= Number(payload.salesPrice);

                    //             cartItem.subTotal =
                    //   globalDiscount(payload.sku, payload.product) * cartItem.quantity;

                    // total.value -= globalDiscount(cartItem.sku, payload.product);
                } else {
                    // cartItem.subTotal =
                    //     Number(skuUtils.extractPrice(payload.sku)) *
                    //     cartItem.quantity;
                    // total.value -= Number(skuUtils.extractPrice(cartItem.sku));

                    cartItem.subTotal =
                        globalDiscount(payload.sku, payload.product)?.price *
                        cartItem.quantity;
                    subTotal.value -= globalDiscount(payload.sku, payload.product)?.salesPrice;
                    total.value -= globalDiscount(
                        cartItem.sku,
                        payload.product
                    )?.price;
                    discount.value -= globalDiscount(
                        cartItem.sku,
                        payload.product
                    )?.discount;
                }
                // cartItem.subTotal = Number(skuUtils.extractPrice(payload.sku)) * cartItem.quantity;
                // total.value -= Number(skuUtils.extractPrice(cartItem.sku));

                quantity.value--;
            }
        }

        localStorage.setItem(
            "cart",
            JSON.stringify({
                items: items.value,
                quantity: quantity.value,
                total: total.value,
                subTotal: subTotal.value,
                discount: discount.value,
            })
        );
    }
    /* Add Coupon discount amount */
    const addCoupon = (amount) => {
        coupon.value = amount;
    };

    // remove cart item
    function removeCartItem(payload) {
        const index = items.value.indexOf(payload);
        if (index != -1) {
            let cartItem = items.value[index];

            quantity.value -= cartItem.quantity;

            // total.value -= Number(skuUtils.extractPrice(payload.sku)) * cartItem.quantity;
            if (payload?.sourceType == "bundle") {
                total.value -= Number(payload.salesPrice) * cartItem.quantity;
                subTotal.value -= Number(payload.salesPrice) * cartItem.quantity;
                // total.value -= globalDiscount(payload.sku, payload.product) * cartItem.quantity;
            } else {
                // total.value -= Number(skuUtils.extractPrice(payload.sku)) * cartItem.quantity;
                subTotal.value -= globalDiscount(payload.sku, payload.product)?.salesPrice *
                cartItem.quantity;
                total.value -=
                    globalDiscount(payload.sku, payload.product)?.price *
                    cartItem.quantity;
                discount.value -=
                    globalDiscount(payload.sku, payload.product)?.discount *
                    cartItem.quantity;
            }
        }

        items.value.splice(index, 1);

        localStorage.setItem(
            "cart",
            JSON.stringify({
                items: items.value,
                quantity: quantity.value,
                total: total.value,
                subTotal: subTotal.value,
                discount: discount.value,
            })
        );
    }

    // remove all cart items
    function removeAll() {
        quantity.value = 0;
        items.value = [];
        total.value = 0;
        coupon.value = 0;

        localStorage.setItem(
            "cart",
            JSON.stringify({
                items: items.value,
                quantity: quantity.value,
                total: total.value,
                subTotal: subTotal.value,
                discount: discount.value,
            })
        );
    }

    function globalDiscount(sku, item) {
        if (!item?.id) {
            return 0;
        }

        // if (sku?.discountedSalesPrice > 0) {
        //   return sku.discountedSalesPrice;
        // }

        if (!globalDiscountStore?.discounts?.length) {
            // return sku?.salesPrice;
            return { 
                price: sku?.salesPrice, 
                salesPrice: sku?.salesPrice, 
                discount: 0
            }
        }

        let discountPrice = 0;
        let discount = 0;
        let salesPrice = Number(sku?.salesPrice);
        let categoryId = item?.categories[0]?.id;
        let findSizeSku = sku?.code;

        globalDiscountStore.discounts.forEach((element) => {
            discount =
                element.discountType === "PERCENTAGE"
                    ? Math.floor((salesPrice * Number(element.value)) / 100)
                    : Number(element.value);

            if (element.type === "FIXED_CATEGORY" && element.valid) {
                let findItem =
                    element?.categories?.length &&
                    element.categories.filter(
                        (i) => i.categoryId === categoryId
                    );
                if (findItem.length) {
                    discountPrice = salesPrice - discount;
                }
            }

            if (element.type === "FIXED_PRODUCT" && element.valid) {
                let findItem =
                    element?.items?.length &&
                    element.items.filter((i) => i.itemId === item.id);
                if (findItem.length) {
                    if (
                        findItem[0]?.skuCode?.length &&
                        findItem[0].skuCode === findSizeSku
                    ) {
                        discountPrice = salesPrice - discount;
                    } else if (findItem[0] && !findItem[0]?.skuCode?.length) {
                        discountPrice = salesPrice - discount;
                    }
                }
            }
        });
console.log({discountPrice});
console.log({salesPrice});
        // return discountPrice > 0 ? discountPrice : salesPrice;
        return { price: discountPrice > 0 ? discountPrice : salesPrice, salesPrice: salesPrice, discount};
    }

    // initial read and set items
    function initialize() {
        if (process.client) {
            let cartJson = localStorage.getItem("cart");
            if (cartJson != null) {
                let cart = JSON.parse(cartJson);

                items.value = cart.items;

                quantity.value = Number(cart.quantity);

                total.value = Number(cart.total);

                subTotal.value = Number(cart.subTotal);
                discount.value = Number(cart.discount);
            }
            let data = JSON.parse(localStorage.getItem("coupon"));
            if (data?.amount) {
                coupon.value = data.amount;
            }
        }
    }

    return {
        items,
        quantity,
        total,
        subTotal,
        discount,
        coupon,
        addToCart,
        increase,
        decrease,
        addCoupon,
        removeCartItem,
        removeAll,
        initialize,
    };
});
