import { ref } from "vue";
import { defineStore } from "pinia";
import { currenciesClient } from '@/assets/apis/config';
export const useCurrenciesStore = defineStore("currenciesStore", () => {

    
    const country = ref({
        img: "/images/one/flags/bdflag.png",
        currency: "BDT",
        country: "bangladesh",
        numCode: '+88',
        rate: 1
    });
    const currencies = ref([]);
    const isLoading = ref(false);

    const setCountry = (param) => {
        if (param.country && param.country.length > 0) {
            let selectedCountry = currencies.value.filter(i => param.currency != "BDT" && i.currency == param.currency);
            if (selectedCountry.length > 0) {
                param.rate = selectedCountry[0].rate;
            }
            country.value = param;
        }
    }

    const fetchCurrencies = async () => {
        currenciesClient.fetchCurrencies({size: 10}).then((res)=> {
            if (res.status == 200 && !res.networkError) {
                if (res.body && res.body.list && res.body.list.length > 0) {
                    let list = [];
                    res.body.list.forEach(element => {
                        list.push({
                            img: element.imageUrl,
                            currency: element.sourceId,
                            country: element.sourceCountry,
                            numCode: '',
                            rate: Number(element.rate)
                        })
                    });
                    currencies.value = list;
                }
            }
        })
    }

    const initialize = async () => {
        await fetchCurrencies();
    }

    return { country, currencies, isLoading, setCountry, initialize };

});
