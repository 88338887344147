<template>
  <div class="container100">
    <div class="signup-content">
      <div
        class="signup-image mobile-mailus"
        style="background-image: url('/images/mail-us.jpg')"
      >
      
      </div>
      <div class="signup-form">
        <h2 class="form-title">We'll love to hear from you</h2>
        <form
          method="POST"
          class="register-form"
          id="register-form"
          @submit.prevent="sendEmail"
        >
          <div class="form-group">
            <input
              type="text"
              name="client_name"
              id="name"
              placeholder="Your Name"
              v-model="fullName"
            />
          </div>
          <pre style="color: #F69320">{{ errorMessage.fullName }}</pre>

          <div class="form-group">
            <input
              type="email"
              name="client_email"
              id="email"
              placeholder="Enter e-mail"
              v-model="email"
            />
          </div>
          <pre style="color: #F69320">{{ errorMessage.email }}</pre>

          <div class="form-group">
            <input
              type="text"
              name="client_subject"
              id="subject"
              placeholder="Subject"
              v-model="subject"
            />
          </div>
          <pre style="color: #F69320">{{ errorMessage.subject }}</pre>

          <div class="form-group">
            <textarea
              placeholder="Body"
              name="client_message"
              v-model="description"
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <pre style="color: #F69320">{{ errorMessage.description }}</pre>
          <button id="signup" class="form-submit">
            Send Your Message
            <i class="fa fa-paper-plane" style="margin-left: 15px"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
// import { Notyf } from 'notyf';
// import 'notyf/// notyf.min.css';
import emailjs from "@emailjs/browser";

const router = useRouter();
// const notyf = new Notyf();

const emit = defineEmits(["close", "onLoginVisible"]);
const fullName = ref("");
const email = ref("");
const subject = ref("");
const description = ref("");
const isValidate = ref(false);

const errorMessage = ref({
  fullName: "",
  email: "",
  subject: "",
  description: "",
});

defineProps({
  visibility: {
    type: Boolean,
    default: false,
  },
});

const clearData = async () => {
  fullName.value = "";
  email.value = "";
  subject.value = "";
  description.value = "";
};
const clearValidations = async () => {
  Object.keys(errorMessage.value).forEach((key) => {
    errorMessage.value[key] = "";
  });
};
const validate = async () => {
  clearValidations();
  if (fullName.value.trim().length == 0) {
    errorMessage.value.fullName = "Please Give Your First Name";
    return false;
  }
  if (email.value.trim().length == 0) {
    errorMessage.value.email = "Please Give Your Email";
    return false;
  }

  if (!email.value.match(/\S+@\S+\.\S+/)) {
    errorMessage.value.email = "Please Give a Correct Email";
    return false;
  }
  if (subject.value.trim().length == 0) {
    errorMessage.value.subject = "Please Tell Us your Query";
    return false;
  }
  if (description.value.trim().length == 0) {
    errorMessage.value.description = "Please Give Us a Message";
    return false;
  }
  return (isValidate.value = true);
};
const sendEmail = (e) => {
  if (fullName.value && fullName.value.length > 0) {
    emailjs
      .sendForm(
        "service_k3p8kyc",
        "template_hb9t21e",
        e.target,
        "WGillGnN5GEGoPwRu"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
            // notyf.success("Mail Sent Successfully");
        },
        (error) => {
          console.log("FAILED...", error.text);
          // notyf.error("Mail Failed to Sent");
        }
      );
    emit("close");
    clearData();
  }
};

</script>

<style scoped>
*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 0px;
  position: initial;
}
display-flex,
.display-flex,
.display-flex-center,
.signup-content,
.signin-content,
.social-login,
.socials {
  display: flex;
  display: -webkit-flex;
}
.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
list-type-ulli,
.socials {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.container100 {
  width: 100%;
    overflow-y: scroll;
    /* max-height: 350px; */
}
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
}
input,
select,
textarea {
  outline: none;
  appearance: unset !important;
  -moz-appearance: unset !important;
  -webkit-appearance: unset !important;
  -o-appearance: unset !important;
  -ms-appearance: unset !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  margin: 0;
}
input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
}
input[type="checkbox"] {
  appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
  -o-appearance: checkbox !important;
  -ms-appearance: checkbox !important;
}
input[type="radio"] {
  appearance: radio !important;
  -moz-appearance: radio !important;
  -webkit-appearance: radio !important;
  -o-appearance: radio !important;
  -ms-appearance: radio !important;
}

figure {
  margin: 0;
}

.clear {
  clear: both;
}
.container {
  width: 900px;
  background: #fff;
  margin: 0 auto;
  box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
}
.display-flex {
  justify-content: space-between;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}
.display-flex-center {
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}
.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.signup-form,
.signup-image,
.signin-form,
.signin-image {
  width: 50%;
  overflow: hidden;
  padding: 5% 5% 5% 7%;
}
.signup-image {
  background-size: cover;
  background-repeat: no-repeat;
}

.form-title {
  margin-bottom: 33px;
  font-weight: 500;
}
.form-sub-title {
  margin-bottom: 33px;
}

figure {
  margin-bottom: 50px;
  text-align: center;
}
.form-submit {
  display: inline-block;
  background: #F69320;
  color: #fff;
  border-bottom: none;
  width: 100%;
  padding: 15px 39px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  margin-top: 25px;
  cursor: pointer;
}
.form-submit:hover {
  background: #fff;
  border: 1px solid #F69320;
  color: #F69320;
}
#signin {
  margin-top: 16px;
}
.signup-image-link {
  font-size: 14px;
  color: #222;
  display: block;
  text-align: center;
}
.term-service {
  font-size: 13px;
  color: #222;
}

.register-form {
  width: 100%;
}
.register-form .check-box {
  display: flex;
  margin-bottom: 15px;
  label {
    padding-left: 10px;
    font-size: 12px;
    color: #222;
    display: inline-block;
    margin-bottom: 0;
    a {
      color: #c5322d;
      text-decoration: none;
      background-color: transparent;
      font-size: 0.65rem;
    }
  }
}
.form-group {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
}
.form-group:last-child {
  margin-bottom: 0;
}
input,
textarea {
  width: 100%;
  display: block;
  border: none;
  border: 1px solid #999;
  padding: 12px 30px;
  font-family: Poppins;
  box-sizing: border-box;
  border-radius: 5px;
}
textarea {
  height: 120px;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #999;
}
input:-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #999;
}
input:-moz-placeholder,
textarea::-moz-placeholder {
  color: #999;
}
input:focus {
  border-bottom: 1px solid #222;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: #222;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: #222;
}
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: #222;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: #222;
}
input[type="checkbox"]:not(old) {
  width: 2em;
  margin: 0;
  padding: 0;
  font-size: 1em;
}
input[type="checkbox"]:not(old) + label {
  display: inline-block;
  line-height: 1.5em;
  margin-top: 6px;
}
input[type="checkbox"]:not(old) + label > span {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 15px;
  margin-bottom: 3px;
  border: 1px solid #999;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  -ms-border-radius: 2px;
  background: #fff;
  background-image: -moz-linear-gradient(white, white);
  background-image: -ms-linear-gradient(white, white);
  background-image: -o-linear-gradient(white, white);
  background-image: -webkit-linear-gradient(white, white);
  background-image: linear-gradient(white, white);
  vertical-align: bottom;
}
input[type="checkbox"]:not(old):checked + label > span {
  background-image: -moz-linear-gradient(white, white);
  background-image: -ms-linear-gradient(white, white);
  background-image: -o-linear-gradient(white, white);
  background-image: -webkit-linear-gradient(white, white);
  background-image: linear-gradient(white, white);
}
input[type="checkbox"]:not(old):checked + label > span:before {
  content: "\f26b";
  display: block;
  color: #222;
  font-size: 11px;
  line-height: 1.2;
  text-align: center;
  font-family: material-design-iconic-font;
  font-weight: 700;
}
.agree-term {
  display: inline-block;
  width: auto;
}
label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  color: #222;
}
.label-has-error {
  top: 22%;
}
label.error {
  position: relative;
  background: url(https://colorlib.com/etc/regform/colorlib-regform-7/images/unchecked.gif)
    no-repeat;
  background-position-y: 3px;
  padding-left: 20px;
  display: block;
  margin-top: 20px;
}
label.valid {
  display: block;
  position: absolute;
  right: 0;
  left: auto;
  margin-top: -6px;
  width: 20px;
  height: 20px;
  background: 0 0;
}
label.valid:after {
  font-family: material-design-iconic-font;
  content: "\f269";
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 16px;
  color: green;
}
.label-agree-term {
  position: relative;
  top: 0%;
  transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
}
.label-agree-term a {
  color: #F69320;
  text-decoration: none;
}
.material-icons-name {
  font-size: 18px;
}
.signin-content {
  padding-top: 67px;
  padding-bottom: 87px;
}
.social-login {
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  margin-top: 80px;
}
.social-label {
  display: inline-block;
  margin-right: 15px;
}
.socials li {
  padding: 5px;
}
.socials li:last-child {
  margin-right: 0;
}
.socials li a {
  text-decoration: none;
}
.socials li a i {
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  transform: translateZ(0);
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.socials li:hover a i {
  -webkit-transform: scale(1.3) translateZ(0);
  transform: scale(1.3) translateZ(0);
}
.zmdi-facebook {
  background: #3b5998;
}
.zmdi-twitter {
  background: #1da0f2;
}
.zmdi-google {
  background: #e72734;
}
.signin-form {
  margin-right: 90px;
  margin-left: 80px;
}
.signin-image {
  margin-left: 110px;
  margin-right: 20px;
  margin-top: 10px;
}
@media screen and (max-width: 1200px) {
  .container {
    width: calc(100% - 30px);
    max-width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 768px) {
  .signup-content,
  .signin-content {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
    overflow-y: scroll;
    max-height: 350px;
  }
  .signup-form {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding: 0 0px;
  }
  .signin-image {
    margin-left: 0;
    margin-right: 0;
    margin-top: 50px;
    order: 2;
    -moz-order: 2;
    -webkit-order: 2;
    -o-order: 2;
    -ms-order: 2;
  }
  .signup-form,
  .signup-image,
  .signin-form,
  .signin-image {
    width: auto;
  }
  .social-login {
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
  }
  .form-button {
    text-align: center;
  }
  .signin-form {
    order: 1;
    -moz-order: 1;
    -webkit-order: 1;
    -o-order: 1;
    -ms-order: 1;
    margin-right: 0;
    margin-left: 0;
    padding: 0 30px;
  }
  .form-title {
    text-align: center;
  }
}
@media screen and (max-width: 400px) {
  .social-login {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
  }
  .social-label {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
</style>

<style>
@media screen and (max-width: 600px){
    .mobile-mailus {
        display: none;
    }
}
</style>

