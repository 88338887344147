<template>
    <div class="right-section" style="display: flex">
        <CommonsNavCurrencyDropdown />
        <div class="right-upper-side">
            <div
                class="upper-side-section profile-section mx-3"
                @click.prevent="showAccount"
            >
                <img class="account-logo" src="/images/user.png" alt="account" style="width: 22px;">
            </div>

            <ul
                class="account-list drop-down-menu"
                v-if="accountList"
                v-click-outside="onOutsideClick"
                :class="[accountList == true && 'list-click']"
            >
                <li
                    class="option"
                    style="font-size: 20px"
                    v-for="(item, index) in accountArray"
                    :key="index"
                    @click.prevent="onAccountClick(item)"
                >
                    <a
                        class="lower-slide-section"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>
                            <i class="fa" :class="item.img"></i>
                            <span>{{ item.title }}</span>
                        </div>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>

            <div
                class="upper-side-section cart-section desktop me-3"
                @click.prevent="showFavorite()"
                v-if="isAuthenticated"
            >
                <div class="d-flex justify-content-center align-items-center" style="cursor: pointer; width: 40px; height: 40px">
                    <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54.98 54.98"
                        class="wishlist"
                        :class="{'active-wishlist': isActive}"
                    >
                        <path
                            class="cls-1"
                            style="stroke-width: 0px;"
                            d="m44.51,22.98c-.1.57-.16,1.14-.29,1.7-.42,1.85-1.32,3.46-2.46,4.96-1.59,2.1-3.49,3.89-5.46,5.61-2.67,2.33-5.36,4.64-8.05,6.95-.49.42-.99.46-1.44.07-3.36-2.92-6.73-5.83-10.06-8.79-1.61-1.43-3.05-3.03-4.24-4.84-1.5-2.28-2.28-4.76-1.98-7.51.3-2.75,1.42-5.08,3.61-6.82,1.81-1.43,3.9-2.01,6.19-1.79,2.8.26,4.81,1.79,6.38,4.02.27.38.5.78.77,1.22.1-.17.18-.32.27-.47,1-1.68,2.28-3.08,4.05-3.96,2.14-1.07,4.36-1.15,6.58-.34,3.22,1.17,5.05,3.61,5.83,6.87.13.55.19,1.11.28,1.66v1.46Zm-17.03,17.22c.06-.04.09-.05.11-.07,3-2.61,6.02-5.19,8.99-7.84,1.75-1.57,3.36-3.28,4.57-5.33,1.21-2.05,1.69-4.25,1.15-6.6-.59-2.6-2-4.57-4.59-5.5-1.79-.64-3.57-.54-5.26.4-1.42.79-2.41,2-3.16,3.41-.34.64-.59,1.33-.89,2-.17.39-.48.6-.91.6-.41,0-.72-.19-.89-.57-.07-.14-.12-.29-.17-.43-.6-1.53-1.4-2.93-2.61-4.07-2.88-2.71-7.27-2.09-9.47.7-2.23,2.83-2.43,5.94-.97,9.18.76,1.69,1.94,3.09,3.2,4.42,1.88,1.98,3.96,3.75,6.02,5.52,1.62,1.39,3.24,2.78,4.88,4.19Z"
                        />
                    </svg>
                </div>
                <span class="wish-span" v-if="wishlistStore.totalCount > 0">{{ wishlistStore.totalCount }}</span>
            </div>

            <div
                class="upper-side-section cart-section desktop"
                @click.prevent="showCart()"
            >
                <div class="d-flex justify-content-center align-items-center">
                    <img src="/images/bag.png" alt="" style="width: 20px;">
                </div>
                <span class="cart-span" v-if="cartStore.quantity > 0">{{ cartStore.quantity }}</span>
            </div>
        </div>

        <CartDropdown
            v-if="cartVisibility"
            :class="{ 'cart-dropdown-active': isActive }"
            class="cart-dropdown"
            @onDropdownModalVisibility="cartVisibility = false"
            :cartStore="cartStore"
            @hideNav="$emit('hideNav')"
        />

        <CommonsModalBaseModal 
            :show="signInModalVisibility" 
            @close="signInModalVisibility = false" 
            maxWidth="60%" 
            :modalFooter="false"
            body-style="padding: 0"
        >
            <SignIn
                v-if="modalTab == 'signIn'"
                @close="signInModalVisibility = false"
                @openSignUp="openSignUp"
            />
        </CommonsModalBaseModal>
        <CommonsModalBaseModal 
            :show="signUpModalVisibility" 
            @close="signUpModalVisibility = false" 
            maxWidth="60%" 
            :modalFooter="false"
            body-style="padding: 0"
        >
            <SignUp
                @openSignIn="openSignIn"
                @close="signUpModalVisibility = false"
            />
        </CommonsModalBaseModal>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import CartDropdown from "../../cart/CartDropdown.vue";
import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth-store";
import PopupModalV2 from "@/components/commons/modal/PopupModalV2.vue";
import SignIn from "@/components/commons/SignIn.vue";
import SignUp from "@/components/commons/SignUp.vue";
import { useWishlistStore } from "@/stores/wishlist-store";

const router = useRouter();
const cartStore = useCartStore();
const authStore = useAuthStore();
const wishlistStore = useWishlistStore();

const cartVisibility = ref(false);
const currencyList = ref(false);
const accountList = ref(false);
const signInModalVisibility = ref(false);
const signUpModalVisibility = ref(false);
const isActive = ref(false);
const emit = defineEmits(["hide-nav"]);
const modalTab = "signIn";

const accountArray = [
    {
        title: "Profile",
        img: "fa-user",
        path: "profile",
    },
    {
        title: "Sign Out",
        img: "fa-power-off",
    },
];

const showCart = async () => {
    cartVisibility.value = !cartVisibility.value;
    isActive.value = true;
};
const openSignUp = () => {
    signUpModalVisibility.value = true;
    signInModalVisibility.value = false;
};
const openSignIn = () => {
    signUpModalVisibility.value = false;
    signInModalVisibility.value = true;
};

const goToOutletPage = () => {
    router.push({
        name: "outlets",
    });
};
const onAccountClick = (item) => {
    if (item.path && item.path == "profile") {
        router.push({
            name: "profile",
        });
    } else {
        onSignOut();
    }
    accountList.value = false;
};

const showAccount = () => {
    if (authStore.currentUser != null) {
        accountList.value = !accountList.value;
    } else {
        signInModalVisibility.value = true;
    }
};

const onSignOut = async () => {
    await authStore.signOut();
    router.push({
        name: "index",
    });
};
const myAccount = computed(() => {
    let title = "";
    if (authStore.currentUser != null) {
        title = authStore.profile.name.toLowerCase();
    } else {
        title = "My Account";
    }
    return title;
});

const isAuthenticated = computed(() => {
    let ac = false;
    if (authStore.currentUser != null) {
        ac = true;
    }
    return ac;
});

const showFavorite = () => {
    if (isAuthenticated.value) {
        router.push({
            name: "profile",
            query: {
                tab: "wishlist",
            },
        });
    } else signInModalVisibility.value = true;
};

const onOutsideClick = () => {
    currencyList.value = false;
    accountList.value = false;
};
</script>

<style lang="scss">
.flag-list {
    display: none;
    max-height: 300px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    opacity: 0;
    overflow: inherit;
    padding: 0;
    position: absolute;
    top: 45px;
    left: -15px;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    z-index: 3026;
    background: #fff;
}
.flag-list:before {
    content: "";
    position: absolute;
    border-color: rgba(194, 225, 245, 0);
    border: solid transparent;
    border-bottom-color: white;
    border-width: 11px;
    margin-left: -10px;
    top: -21px;
    right: 40%;
    z-index: 1;
}

.flag-list:after {
    content: "";
    position: absolute;
    right: 40%;
    top: -21px;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 10px;
    border-bottom-color: #2b1a41;
    z-index: 0;
}
.list-click {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0);
    max-height: 300px;
    display: block !important;
}
.account-list {
    display: none;
    max-height: 300px;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    overflow-y: inherit;
    padding: 15px;
    position: absolute;
    top: 45px;
    right: 0%;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    z-index: 3026;
    background: #fff;
    border: 1px solid #cacaca;
}
.drop-down-menu:before {
    content: "";
    position: absolute;
    border-color: rgba(194, 225, 245, 0);
    border: solid transparent;
    border-bottom-color: white;
    border-width: 11px;
    margin-left: -10px;
    top: -21px;
    right: 40%;
    z-index: 1;
}

.drop-down-menu:after {
    content: "";
    position: absolute;
    right: 40%;
    top: -21px;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 10px;
    border-bottom-color: #2b1a41;
    z-index: 0;
}
.account-list .option:hover {
    color: #F69320;
}
.account-list .option .lower-slide-section span {
    margin-left: 10px;
}
.option {
    opacity: 1 !important;
    display: list-item !important;
    cursor: pointer;
    font-weight: 400;
    line-height: 40px !important;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 10px;
    padding-right: 40px;
    padding: 10px 20px;
    text-align: left;
    transition: all 0.2s;
    margin-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    div {
        .bd-flag {
            margin-right: 5px;
        }
    }
}
.option:last-child {
    border-bottom: none;
}
</style>
