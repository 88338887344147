<template>
    <div class="navbar-items">
        <div class="logo-section item-name nav-logo-container" style="display: none">
            <RouterLink to="/" @click.prevent="$emit('gotoIndexPage')">
                <img
                    class="logo-img" 
                    src="/logo/logo.png" 
                    alt="Logo" 
                />
            </RouterLink>
        </div>
        <div 
            class="item-name item-has-mega-menu nav-item dropdown" 
            @click="handleHover" 
            v-for="(category, i) in categoryStore.categories" 
            :key="i"
            :class="{'ps-lg-5': i == 0, 'pe-lg-5': categoryStore.categories.length - 1}"
            @mouseenter="scrollHandle = true"
            @mouseleave="scrollHandle = false"
        >
                <a 
                    class="nav-link" 
                    :class="{'dropdown-toggle': category?.children?.length}"
                    href="#" 
                    id="navbarDropdown" 
                    role="button" 
                    data-bs-toggle="dropdown" 
                    aria-expanded="false"
                    :href="itemLink({
                        categoryId: category.id,
                        categoryName: category.name,
                        categoryBanner: category.img,
                    })"
                    @click.prevent="goToShopPage({
                        categoryId: category.id,
                        categoryName: category.name,
                        categoryBanner: category.img,
                    })"
                >
                    {{category.name}} 
                </a>
                <ul v-if="category?.children?.length" class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li v-for="(item, i) in category.children" :key="i">
                        <a class="dropdown-item nav-item dropdown" href="#">
                            <a
                                class="nav-link" 
                                :class="{'dropdown-toggle': item?.children?.length}"
                                href="#" 
                                id="navbarDropdown" 
                                role="button" 
                                data-bs-toggle="dropdown" 
                                aria-expanded="false"
                                :href="itemLink({
                                    categoryId: findChildRoom(item).id,
                                    categoryName: findChildRoom(item).name,
                                    categoryBanner: findChildRoom(item).img,
                                })"
                                @click.prevent="goToShopPage({
                                    categoryId: findChildRoom(item).id,
                                    categoryName: findChildRoom(item).name,
                                    categoryBanner: findChildRoom(item).img,
                                })"
                            >
                                {{item.name}} 
                            </a>
                            <ul v-if="item.children?.length > 0" class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a  class="dropdown-item"
                                        :href="itemLink({
                                            categoryId: findChildRoom(item).id,
                                            categoryName: findChildRoom(item).name,
                                            categoryBanner: findChildRoom(item).img,
                                        })"
                                        @click.prevent="goToShopPage({
                                            categoryId: findChildRoom(item).id,
                                            categoryName: findChildRoom(item).name,
                                            categoryBanner: findChildRoom(item).img,
                                        })"
                                    >
                                        All {{item.name}}
                                    </a>
                                </li>
                                <li v-for="(childItem, j) in item.children" :key="i">
                                    <a  class="dropdown-item"
                                        :href="itemLink({
                                            categoryId: findChildRoom(childItem).id,
                                            categoryName: findChildRoom(childItem).name,
                                            categoryBanner: findChildRoom(childItem).img,
                                        })"
                                        @click.prevent="goToShopPage({
                                            categoryId: findChildRoom(childItem).id,
                                            categoryName: findChildRoom(childItem).name,
                                            categoryBanner: findChildRoom(childItem).img,
                                        })"
                                    >{{childItem.name}}</a>
                                </li>
                            </ul>
                        </a>
                    </li>
                    <!-- <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><hr class="dropdown-divider"></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li> -->
                </ul>

            <!-- <a 
                :href="itemLink({
                    categoryId: category.id,
                    categoryName: category.name,
                    categoryBanner: category.img,
                })"
                @click.prevent="goToShopPage({
                    categoryId: category.id,
                    categoryName: category.name,
                    categoryBanner: category.img,
                })"
            >
                {{category.name}} 
                <i class="fa fa-angle-down" v-if="category?.children?.length"></i>
            </a>
            <div 
                class="mega-menu-container style2 p-5" 
                v-if="category?.children?.length && isShowDropDownMenu" 
                @click="handleHover"
            >
                <div class="row m-0">
                    <div class="col-lg-2 px-0" v-for="(item, i) in category.children" :key="i">
                        <div class="category-item">
                            <a 
                                data-toggle="collapse"
                                role="button" 
                                aria-expanded="false" 
                                aria-controls="mobile-product1"
                                :href="itemLink({
                                    categoryId: findChildRoom(item).id,
                                    categoryName: findChildRoom(item).name,
                                    categoryBanner: findChildRoom(item).img,
                                })"
                                @click.prevent="goToShopPage({
                                    categoryId: findChildRoom(item).id,
                                    categoryName: findChildRoom(item).name,
                                    categoryBanner: findChildRoom(item).img,
                                })"
                            >
                                <h6 class="title">{{item.name}}</h6>
                            </a>
                            <ul class="list-unstyled">
                                <li>
                                    <a 
                                        :href="itemLink({
                                            categoryId: findChildRoom(item).id,
                                            categoryName: findChildRoom(item).name,
                                            categoryBanner: findChildRoom(item).img,
                                        })"
                                        @click.prevent="goToShopPage({
                                            categoryId: findChildRoom(item).id,
                                            categoryName: findChildRoom(item).name,
                                            categoryBanner: findChildRoom(item).img,
                                        })"
                                    >
                                        All {{item.name}}
                                    </a>
                                </li>
                                <li v-for="(childItem, j) in item.children" :key="i">
                                    <a 
                                        :href="itemLink({
                                            categoryId: findChildRoom(childItem).id,
                                            categoryName: findChildRoom(childItem).name,
                                            categoryBanner: findChildRoom(childItem).img,
                                        })"
                                        @click.prevent="goToShopPage({
                                            categoryId: findChildRoom(childItem).id,
                                            categoryName: findChildRoom(childItem).name,
                                            categoryBanner: findChildRoom(childItem).img,
                                        })"
                                    >{{childItem.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>      
    </div>
</template>

<script setup>
import { useCategoryStore } from "@/stores/category-store";
const router = useRouter();
const emit = defineEmits(["hide-nav"]);
const categoryStore = useCategoryStore();

const categories = ref([]);
const isShowDropDownMenu = ref(true);
const scrollHandle = ref(false);

const itemLink = (obj) => {
    let link = "#";

    if (obj.categoryId && obj.categoryId.length > 0) {
        link = `/shop?categoryId=${obj.categoryId}&categoryName=${obj.categoryName}&categoryBanner=${obj.categoryBanner}`;
    } else if (obj.name && obj.name.length > 0) {
        link = obj.name;
    }

    return link;
};

const goToShopPage = (obj) => {
  router.push({
      name: "shop",
      query: obj
  })
  emit('hideNav');
}

const findChildRoom = (room)=> {
    let obj = {
        id: '',
        name: '',
        img: '',
        madeBy: []
    }
    if (room && room.id && room.id.length > 0) {
        obj.id = room.id;
        obj.name = room.name;
        obj.img = room.extras && room.extras.shop_banner_image_url ? room.extras.shop_banner_image_url : '';
    }
    if (room && room.children && room.children.length <= 0) {
        if (room.options && room.options.length > 0) {
            let discriminator = room.options.filter(i => i.discriminator == false)
            if (discriminator.length > 0) {
                discriminator[0].values.forEach(element => {
                    obj.madeBy.push(element.displayLabel)
                });
            }
        }
    }
    return obj;
};

const  handleHover = () => {
	isShowDropDownMenu.value = false;
    setTimeout(() => {
        isShowDropDownMenu.value = true;
    }, 1000);
}

// watch(scrollHandle, () => {
//     if (process.client) {
//         const body = document.querySelector("body");
//         if (scrollHandle.value) {
//             body.style.setProperty("overflow", "hidden")
//         } else if (!scrollHandle.value) {
//             body.style.removeProperty("overflow")
//         }
//     }
// },{immediate: true, deep: true});

onMounted(async () => {
    if (process.client && localStorage.getItem("all-categories")) {
        let categoriesVal = JSON.parse(localStorage.getItem("all-categories"));
        categories.value = categoriesVal.categories;
    }
});
</script>

<style>
.navbar-items .item-has-children {
  position: relative;
}
.navbar-items .item-has-children.active > a {
  color: #F69320;
}
.navbar-items .item-has-children > a i {
  font-size: 12px;
}
.navbar-items .item-has-children .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 220px;
  background-color: #fff;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 0.3s ease;
  /* border-top: 3px solid red; */
  z-index: 2;
}
.navbar-items .item-has-children .submenu:hover {
    display: block;
}
.navbar-items .item-has-children .submenu li a {
  display: block;
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid rgba(89, 184, 40, 0.1);
}
/* .navbar-items .item-has-children .submenu li a:hover {
  background-color: #F69320;
  color: white;
}
.navbar-items .item-has-children .submenu li.active > a {
  background-color: #F69320;
  color: white;
} */
.navbar-items .item-has-children .submenu .submenu {
  left: 100%;
  top: 0;
}
.navbar-items .item-has-children:hover > .submenu {
  transform: scaleY(1);
}
@media only screen and (max-width: 1200px) {
    .navbar-items .item-has-children .submenu {
        position: static;
        transform: scaleY(1);
    }
    .navbar-items .item-has-children .submenu li a {
        border-bottom: none;
    }
    .navbar-items .item-has-children .submenu li a:hover {
        background-color: transparent;
        color: #F69320;
    }
}
.navbar-items .item-has-children .item-has-children > a {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.navbar-items .item-has-mega-menu {
    position: initial;
}

.navbar-items .item-has-mega-menu > a i {
    font-size: 12px;
}
.navbar-items .item-has-mega-menu .mega-menu-container {
    position: absolute;
    left: 0;
    top: 100%;
    /* top: 116%; */
    /* max-width: 1200px; */
    max-width: 100%;
    width: 100%;
    max-height: 100vh;
    /* height: 91vh; */
    height: calc(100vh - 93px);
    /* max-height: 480px; */
    padding: 50px 30px 30px 30px;
    overflow-y: auto;
    background-color: #fff;
    transform: scaleY(0);
    transform-origin: top;
    /* transition: all 0.3s ease; */
    z-index: 1024;
    /* box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.06); */
    /* margin-top: 12px; */
}
.navbar-items .item-has-mega-menu .mega-menu-container .category-item .title {
    font-size: 14px;
    color: #2c3e50;
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 500;
    /* margin-left: 25px; */
}
.navbar-items .item-has-mega-menu .mega-menu-container .category-item .active {
  color: #F69320;
}
.navbar-items .item-has-mega-menu .mega-menu-container .category-item ul {
  margin-bottom: 35px;
}
.navbar-items .item-has-mega-menu .mega-menu-container .category-item ul li a {
  padding: 5px 0;
  color: #4b566b;
  font-size: 13px;
  font-weight: 400;
}
.navbar-items .item-has-mega-menu .mega-menu-container .category-item ul li a:hover {
  color: #F69320;
}
.navbar-items .item-has-mega-menu:hover > a {
  color: #F69320;
}
.navbar-items .item-has-mega-menu:hover .mega-menu-container {
  transform: scaleY(1);
}


/** ---------- open drop down on hover ---------- */
.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

.dropdown-menu {
    left: 12% !important;
}

</style>
