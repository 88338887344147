import { UploadClient } from '@uploadcare/upload-client';
const uploadClient = new UploadClient({ publicKey: 'e7fd2ac5bfa2a18a9221' });

class UploadCareClient {
    constructor() {}

    async uploadFile(fileData) {
        try {
            let resp = await uploadClient.uploadFile(fileData);
            let json;
            try {
                json = await resp;
            } catch (parseError) {
                return {
                    networkError: false,
                };
            }
            return {
                status: 201,
                body: json,
                networkError: false,
            };
        } catch (error) {
            console.log(error);
            return {
                networkError: true,
            };
        }
    }
}

export default UploadCareClient;
