<template>
    <div class="container100">
        <div class="signin-content">
            <div class="signin-image mobile-mailus">
                <figure>
                    <img
                        src="/logo/logo.png"
                        alt="sing up image"
                        style="height: 150px"
                    />
                </figure>
                <a
                    href="/sign-up"
                    @click.prevent="$emit('openSignUp')"
                    class="signup-image-link"
                    >Create an account</a
                >
            </div>
            <div class="signin-form">
                <h2 class="form-title">Sign In</h2>
                <div class="form-group">
                    <label for="your_name"><i class="fa fa-user"></i></label>
                    <input
                        type="text"
                        name="your_name"
                        id="your_name"
                        placeholder="Your Name"
                        v-model="userName"
                    />
                </div>
                <pre style="color: #F69320">{{ errorMessage.userName }}</pre>
                <div class="form-group">
                    <label for="your_pass"><i class="fa fa-lock"></i></label>
                    <input
                        type="password"
                        name="your_pass"
                        id="your_pass"
                        placeholder="Password"
                        v-model="password"
                    />
                </div>
                <pre style="color: #F69320">{{ errorMessage.password }}</pre>
                
                <input
                    type="submit"
                    name="signin"
                    id="signin"
                    class="form-submit"
                    value="Sign In"
                    @click.prevent="onSubmit()"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth-store";
// import { Notyf } from 'notyf';
// import 'notyf/// notyf.min.css';

const router = useRouter();
// const notyf = new Notyf();

const authStore = useAuthStore();
const emit = defineEmits(["close", "onSignup"]);
const userName = ref("");
const password = ref("");
const showSpinner = ref(false);
const isValidate = ref(false);

const errorMessage = ref({
    userName: "",
    password: "",
});

defineProps({
    visibility: {
        type: Boolean,
        default: false,
    },
});

const clearData = async () => {
    userName.value = "";
    password.value = "";
};
const clearValidations = async () => {
    Object.keys(errorMessage.value).forEach((key) => {
        errorMessage.value[key] = "";
    });
};
const validate = async () => {
    clearValidations();
    let isOk = true;
    if (userName.value.trim().length == 0) {
        errorMessage.value.userName = "Please Give Your User Name";
        isOk = false;
        return isOk;
    }

    if (password.value.trim().length < 6) {
        errorMessage.value.password =
            "Password Must Be More Than Six Character";
        isOk = false;
        return isOk;
    }
    isValidate.value = true;
    return true;
};

const onSubmit = () => {
    if (validate() && isValidate.value == true) {
        showSpinner.value = true;
        let sumitedData = {
            source: "DEFAULT",
            type: "DEFAULT",
            userName: userName.value,
            password: password.value,
        };
        authStore
            .signIn(sumitedData)
            .then(() => {
                clearData();
                onCloseModal();
                // notyf.success("Successfully Logged In");
                // notyf.success("Successfully Logged In");
                showSpinner.value = false;
                router.push({
                    name: "index",
                });
                emit("close");
            })
            .catch((error) => {
                showSpinner.value = false;
                // notyf.error("Failed to Sign In");
                if (error == "auth_error") {
                    // this.errorToast("Wrong Username or Password");
                    errorMessage.value.userName = "Wrong Email or Mobile No";
                    errorMessage.value.password = "Wrong Password";
                    console.log("Wrong Username or Password");
                } else if (error == "already_logedin") {
                    errorMessage.value.userName =
                        "Your Mobile or Email is already associated with another account";
                    // this.errorToast("Network Error, Please Check Your Internet Connection");
                    console.log(
                        "Network Error, Please Check Your Internet Connection"
                    );
                } else if (error == "network_error") {
                    // this.errorToast("Network Error, Please Check Your Internet Connection");
                    console.log(
                        "Network Error, Please Check Your Internet Connection"
                    );
                } else {
                    // this.errorToast("System is under maintenance");
                    console.log("System is under maintenance");
                }
            });
    }
};

const onCloseModal = async () => {
    emit("close");
};
</script>

<style scoped>
*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 0px;
    position: initial;
}
.container100 {
    width: 100%;
}
display-flex,
.display-flex,
.display-flex-center,
.signup-content,
.signin-content,
.social-login,
.socials {
    display: flex;
    display: -webkit-flex;
}
list-type-ulli,
.socials {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a:focus,
a:active {
    text-decoration: none;
    outline: none;
    transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -webkit-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
    -ms-transition: all 300ms ease 0s;
}
input,
select,
textarea {
    outline: none;
    appearance: unset !important;
    -moz-appearance: unset !important;
    -webkit-appearance: unset !important;
    -o-appearance: unset !important;
    -ms-appearance: unset !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    margin: 0;
}
input:focus,
select:focus,
textarea:focus {
    outline: none;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    -ms-box-shadow: none !important;
}
input[type="checkbox"] {
    appearance: checkbox !important;
    -moz-appearance: checkbox !important;
    -webkit-appearance: checkbox !important;
    -o-appearance: checkbox !important;
    -ms-appearance: checkbox !important;
}
input[type="radio"] {
    appearance: radio !important;
    -moz-appearance: radio !important;
    -webkit-appearance: radio !important;
    -o-appearance: radio !important;
    -ms-appearance: radio !important;
}
figure {
    margin: 0;
}
.main {
    background: #f8f8f8;
    padding: 150px 0;
}
.clear {
    clear: both;
}
.container {
    width: 900px;
    background: #fff;
    margin: 0 auto;
    box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -o-border-radius: 20px;
    -ms-border-radius: 20px;
}
.display-flex {
    justify-content: space-between;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -o-align-items: center;
    -ms-align-items: center;
}
.display-flex-center {
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -o-align-items: center;
    -ms-align-items: center;
}
.position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.signup {
    margin-bottom: 150px;
}
.signup-content {
    padding: 75px 0;
}
.signup-form,
.signup-image,
.signin-form,
.signin-image {
    width: 50%;
    overflow: hidden;
    padding: 5% 5%;
}
.signup-image {
    margin: 0 55px;
}
.form-title {
    margin-bottom: 33px;
}
.signup-image {
    margin-top: 45px;
}
figure {
    margin-bottom: 50px;
    text-align: center;
}
.form-submit {
    display: inline-block;
    /* background: #F69320; */
    background: #000;
    color: #fff;
    border-bottom: none;
    width: 100%;
    padding: 15px 39px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    margin-top: 25px;
    cursor: pointer;
}
.form-submit:hover {
    /* background: #4292dc; */
    background: #fff;
    border: 1px solid #F69320;
    color: #F69320;
}
#signin {
    margin-top: 16px;
}
.signup-image-link {
    font-size: 14px;
    color: #222;
    display: block;
    text-align: center;
}
.term-service {
    font-size: 13px;
    color: #222;
}
.signup-form {
    margin-left: 60px;
    margin-right: 60px;
    padding-left: 34px;
}
.register-form {
    width: 100%;
}
.register-form .check-box {
    display: flex;
    margin-bottom: 15px;
    label {
        padding-left: 10px;
        font-size: 12px;
        color: #222;
        display: inline-block;
        /* // margin-bottom: 0.5rem; */
        margin-bottom: 0;
        a {
            color: #c5322d;
            text-decoration: none;
            background-color: transparent;
            font-size: 0.65rem;
        }
    }
}
.form-group {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
}
.form-group:last-child {
    margin-bottom: 0;
}
input {
    width: 100%;
    display: block;
    border: none;
    border-bottom: 1px solid #999;
    padding: 6px 30px;
    font-family: Poppins;
    box-sizing: border-box;
}
input::-webkit-input-placeholder {
    color: #999;
}
input::-moz-placeholder {
    color: #999;
}
input:-ms-input-placeholder {
    color: #999;
}
input:-moz-placeholder {
    color: #999;
}
input:focus {
    border-bottom: 1px solid #222;
}
input:focus::-webkit-input-placeholder {
    color: #222;
}
input:focus::-moz-placeholder {
    color: #222;
}
input:focus:-ms-input-placeholder {
    color: #222;
}
input:focus:-moz-placeholder {
    color: #222;
}
input[type="checkbox"]:not(old) {
    width: 2em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    /* display: none; */
}
input[type="checkbox"]:not(old) + label {
    display: inline-block;
    line-height: 1.5em;
    margin-top: 6px;
}
input[type="checkbox"]:not(old) + label > span {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 15px;
    margin-bottom: 3px;
    border: 1px solid #999;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    -ms-border-radius: 2px;
    background: #fff;
    background-image: -moz-linear-gradient(white, white);
    background-image: -ms-linear-gradient(white, white);
    background-image: -o-linear-gradient(white, white);
    background-image: -webkit-linear-gradient(white, white);
    background-image: linear-gradient(white, white);
    vertical-align: bottom;
}
input[type="checkbox"]:not(old):checked + label > span {
    background-image: -moz-linear-gradient(white, white);
    background-image: -ms-linear-gradient(white, white);
    background-image: -o-linear-gradient(white, white);
    background-image: -webkit-linear-gradient(white, white);
    background-image: linear-gradient(white, white);
}
input[type="checkbox"]:not(old):checked + label > span:before {
    content: "\f26b";
    display: block;
    color: #222;
    font-size: 11px;
    line-height: 1.2;
    text-align: center;
    font-family: material-design-iconic-font;
    font-weight: 700;
}
.agree-term {
    display: inline-block;
    width: auto;
}
label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    color: #222;
}
.label-has-error {
    top: 22%;
}
label.error {
    position: relative;
    background: url(https://colorlib.com/etc/regform/colorlib-regform-7/images/unchecked.gif)
        no-repeat;
    background-position-y: 3px;
    padding-left: 20px;
    display: block;
    margin-top: 20px;
}
label.valid {
    display: block;
    position: absolute;
    right: 0;
    left: auto;
    margin-top: -6px;
    width: 20px;
    height: 20px;
    background: 0 0;
}
label.valid:after {
    font-family: material-design-iconic-font;
    content: "\f269";
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 16px;
    color: green;
}
.label-agree-term {
    position: relative;
    top: 0%;
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
}
.label-agree-term a {
    color: #F69320;
    text-decoration: none;
}
.material-icons-name {
    font-size: 18px;
}
.signin-content {
    padding-top: 67px;
    padding-bottom: 87px;
}
.social-login {
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -o-align-items: center;
    -ms-align-items: center;
    margin-top: 80px;
}
.social-label {
    display: inline-block;
    margin-right: 15px;
}
.socials li {
    padding: 5px;
}
.socials li:last-child {
    margin-right: 0;
}
.socials li a {
    text-decoration: none;
}
.socials li a i {
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    transform: translateZ(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.socials li:hover a i {
    -webkit-transform: scale(1.3) translateZ(0);
    transform: scale(1.3) translateZ(0);
}
.zmdi-facebook {
    background: #3b5998;
}
.zmdi-twitter {
    background: #1da0f2;
}
.zmdi-google {
    background: #e72734;
}
.signin-form {
    /* margin-right: 90px;
    margin-left: 80px; */
}
.signin-image {
    margin-left: 110px;
    margin-right: 20px;
    margin-top: 10px;
}
@media screen and (max-width: 1200px) {
    .container {
        width: calc(100% - 30px);
        max-width: 100%;
    }
}
@media screen and (min-width: 1024px) {
    .container {
        max-width: 1200px;
    }
}
@media screen and (max-width: 768px) {
    .signup-content,
    .signin-content {
        flex-direction: column;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -o-flex-direction: column;
        -ms-flex-direction: column;
        justify-content: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -o-justify-content: center;
        -ms-justify-content: center;
    }
    .signup-form {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding: 0 30px;
    }
    .signin-image {
        margin-left: 0;
        margin-right: 0;
        margin-top: 50px;
        order: 2;
        -moz-order: 2;
        -webkit-order: 2;
        -o-order: 2;
        -ms-order: 2;
    }
    .signup-form,
    .signup-image,
    .signin-form,
    .signin-image {
        width: auto;
    }
    .social-login {
        justify-content: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -o-justify-content: center;
        -ms-justify-content: center;
    }
    .form-button {
        text-align: center;
    }
    .signin-form {
        order: 1;
        -moz-order: 1;
        -webkit-order: 1;
        -o-order: 1;
        -ms-order: 1;
        margin-right: 0;
        margin-left: 0;
        padding: 0 30px;
    }
    .form-title {
        text-align: center;
    }
}
@media screen and (max-width: 400px) {
    .social-login {
        flex-direction: column;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -o-flex-direction: column;
        -ms-flex-direction: column;
    }
    .social-label {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
</style>
