<template>
    <button class="btn base_btn" :class="classObjects, color" :disabled="disabled || loading" @click.prevent="onClickEmit" >
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <i :class="icon" aria-hidden="true"></i>
        {{ caption }}
        <slot />
    </button>
</template>

<script setup>

const props = defineProps({
    color: {
        type: String,
        default: 'btn-primary'
    },
    icon: {
        type: String,
        default: ''
    },
    loading: {
        type: Boolean,
        default: false
    },
    tooltipText: {
        type: String,
        default: ''
    },
    block: {
        type: Boolean,
        default: false
    },
    large: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    outline: {
        type: Boolean,
        default: false
    },
    rounded: {
        type: Boolean,
        default: false
    },
    caption: {
        type: String,
        default: ''
    },
    clickable: {
        type: Boolean,
        default: true
    },
    href: {
        type: String,
        default: ''
    }
});

const emits = defineEmits(['on-click']);

const classObjects = reactive({
    rounded:props.rounded,
});

const onClickEmit = () => {
    if(props.clickable) {
        emits('on-click');
    }
}
</script>

<style lang="scss" scoped>

    .base_btn {
        box-shadow: 0 8px 24px -8px rgba(31, 63, 99, 0.4);
        border: none;
    }

    .rounded {
        border-radius: 100% !important;
    }

    .btn-primary {
        background: #1f3f63;
        color: #fff
    }

    .btn-success {
        background: #667632;
        color: #fff
    }

    .btn-danger {
        background: brown;
        color: #fff;
    }

    .btn-warning {
        background: #FFD859;
        color: #000;
    }

    .btn-info {
        background: #0000FF;
        color: #fff
    }

    .btn-light {
        background: #ffff;
        color: #0000
    }

    .btn-dark {
        background-color: #0000 !important;
        color: #ffff;
    }

    .btn-link {
        background: #008080;
        color: #000;
    }

    .btn-gray  {
        background: #f5f6f6;
        color: #1f3f63;
    }
    // , btn-success, , btn-warning, btn-info, btn-light, btn-dark, btn-link
</style>