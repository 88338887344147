import { ref } from "vue";
import { defineStore } from "pinia";
import { optionsClient } from '@/assets/apis/config';
export const useAttributeStore = defineStore("attributeStore", () => {
    
    const attributes = ref([]);
    const isLoading = ref(false);
    const TIMESTAMP = Date.now();

    const fetchAllAttribute = async () => {
        let currentCategories = ref(-1);
        let totalCategories = ref(-1);
        const req = {
          page: 1,
          size: 50,
          fetchCount: true,
          textParams: [],
        };

        // {"field":"parentId","value":"","matchType":"EXACT"}
      
        let nextPageToken = "";

      
        do {
          let res = await optionsClient.search(req);

          if ((res.status = 200 && !res.networkError)) {
            if (res.body && res.body.list && res.body.list.length > 0) {
              let resArr = [];
              res.body.list.forEach((element) => {
                if (element && element.id.length > 0) {
                  resArr.push(element);
                }
              });
              let list = res.body.list.sort((x, y) => x.sortOrder - y.sortOrder);
              attributes.value = attributes.value.concat(list);
              let clone = JSON.parse(JSON.stringify(attributes.value));
              nextPageToken = res.body.nextPageToken;
              currentCategories.value = attributes.value.length;
            //   if (res.body.total > -1) {
            //     totalCategories.value = res.body.total;
            //     req.fetchCount = false;
            //   }
              req.page++;
                if (process.client && currentCategories.value > 0) {

                  localStorage.setItem("all-attributes", JSON.stringify({ attributes: attributes.value, initial: TIMESTAMP,
                    // localStorage.setItem("all-attributes", JSON.stringify({ attributes: clonedCategories.value, initial: TIMESTAMP,
                        expiresOn: TIMESTAMP + 1000 * 60 * 60 * 24 * 1, // 7day
                    })
                    );
                }
            }
            isLoading.value = false;
          }
        } while (nextPageToken != "");
        
    };

    function initialize(){
        isLoading.value = true;
        if (process.client && localStorage.getItem("all-attributes")) {
            let attributesVal = JSON.parse(localStorage.getItem("all-attributes"));
            let expireDate = attributesVal.expiresOn;

            if (Date.now() > expireDate) {
                localStorage.removeItem("all-attributes");
                // fetchAllAttribute();
            } 
            // else {
            //     attributes.value = attributesVal.attributes;
            //     isLoading.value = false;
            // }
            fetchAllAttribute();
        } else {
            fetchAllAttribute();
        }
    }

    return { attributes, isLoading, initialize };

});
