<template>
   <div class="w-100 d-flex justify-content-between align-items-center" v-click-outside="onOutsideClick">
        <div class="input-group py-1 rounded-2" :class="{'border bg-input':showSearchInput}">
            <span class="input-group-text border-0" :class="{'bg-input':showSearchInput, 'bg-white': !showSearchInput}" id="basic-addon1">
                <i class="fa fa-search fs-5" @click="showSearchInput = !showSearchInput"></i>
            </span>
            <input 
                v-if="showSearchInput" 
                v-model="query"
                type="text" 
                class="form-control border-0 bg-input search-input" 
                placeholder="Search..." 
                aria-label="Search" 
                aria-describedby="basic-addon1"
                @keyup.enter.prevent="gotoResultPage"
                autofocus
            >
        </div>
    </div>
</template>

<script setup>
const router = useRouter();

const emit = defineEmits(['hide-nav']);

const query = ref("");
const showSearchInput = ref(false);

const gotoResultPage = () => {
    if (query.value && query.value.length > 0){
        let qv = ''
        qv = query.value
        router.push({
            name: "shop",
            query: {
                query: qv,
                categoryName: 'all'
            },
        });
        emit('hide-nav')
    } else {
        showSearchInput.value = !showSearchInput.value
    }
    
    query.value = "";
    
};



const onOutsideClick = () => {
    showSearchInput.value = false
};
</script>

<style lang="scss">
.search-input[type=text]:focus {
  background-color: #F6F7F8;
}

.bg-input {
    background-color: #F6F7F8;
}
</style>
