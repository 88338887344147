import BaseClient from "./clients/BaseClient";
import OrdersPlaceClient from "./clients/OrdersPlaceClient";
import SalesClient from "./clients/SalesClient";
import CmsClient from "./clients/CmsClient";
import CmsV2Client from "./clients/CmsV2Client";
import CurrenciesClient from "./clients/CurrenciesClient";
import ItemsClient from "./clients/ItemsClient";
import ItemsV3Client from "./clients/ItemsV3Client";
import StocksClient from "./clients/StocksClient";
import ItemsBundleClient from "./clients/ItemsBundleClient";
import XeroxClient from "./http-commons/XeroxClient";
import AuthClient from "./clients/AuthClient.js";
import UploadCareClient from "./http-commons/UploadCareClient";

// const server = "LOCAL";
const server = "PRODUCTION";

const hostType = (server == "PRODUCTION") ? "https" : "http";

/*LOCAL IP*/
const localIp = "192.168.0.190:44334";

/*SERVER IP*/
const serverIpOwn = "146.190.103.161:44334";
const serverIpClient = "api.akhtar.com.bd";


const baseHost = (server == "PRODUCTION") ? (hostType == "https") ? serverIpClient : serverIpOwn : localIp;

const host = `${hostType}://${baseHost}`;

/*LOCAL apiKey*/
const localApiKey = '9lAXmGp7bwytdKxgJLvjKkCzo8EbgrXvNhg7BmK1x1bG'; // local server
/*PRODUCTION SERVER apiKey*/
const serverApiKey = 'pDXJ08oawelio6EYwwgQyXs6KJj8Qerz8Cq0zvdqqB5z'; // Production server

// const apiKey = 'pDXJ08oawelio6EYwwgQyXs6KJj8Qerz8Cq0zvdqqB5z'; // delta Production server api
const apiKey = 'w2mm3VEnNlkt3nvMEP2rm6TNqwyQXrqXnt08loMjEqx'; // akhter Production server api

// const apiKey = (server == "PRODUCTION") ? serverApiKey : localApiKey;

const authClient = new AuthClient(host, apiKey);
const itemsClient = new ItemsClient(host, apiKey, '/api/items');
const itemsV2Client = new BaseClient(host, apiKey, '/api/v2/items');
const itemsV3Client = new ItemsV3Client(host, apiKey, '/api/v3/items');
const itemIndicesClient = new BaseClient(host, apiKey, '/api/search/indices/items');
const itemBundlesClient = new ItemsBundleClient(host, apiKey, '/api/bundles');
const wishlistsClient = new BaseClient(host, apiKey, '/api/wishlists/items');
const cmsClient = new CmsClient(host, apiKey, '/api/v2/items/cms');
const cmsV2Client = new CmsV2Client(host, apiKey, '/api/cms');
const currenciesClient = new CurrenciesClient(host, apiKey, '/api/currencies/conversions');
const productsClient = new BaseClient(host, apiKey, '/api/v2/items/indices');
const categoriesClient = new BaseClient(host, apiKey, '/api/categories')
const ordersPlaceClient = new OrdersPlaceClient(host, apiKey);
const salesClient = new SalesClient(host, apiKey, '/api/sales');
const stocksClient = new StocksClient(host, apiKey, '/api/stocks');
const optionsClient = new BaseClient(host, apiKey, '/api/options');
const kvpsClient = new BaseClient(host, apiKey, '/api/kvps');
const snippetsClient = new BaseClient(host, apiKey, '/api/snippets');
const collectionsClient = new BaseClient(host, apiKey, '/api/collections');
const branchesClient = new BaseClient(host, apiKey, '/api/branches');
// const branchesClient = new BaseGandalfClient(host, apiKey, '/api/branches');
const uploadClient = new UploadCareClient();
const xeroxClient = new XeroxClient(host, apiKey);

export {
    host,
    apiKey,
    itemsClient,
    itemsV2Client,
    itemsV3Client,
    itemIndicesClient,
    itemBundlesClient,
    wishlistsClient,
    cmsClient,
    cmsV2Client,
    currenciesClient,
    productsClient,
    categoriesClient,
    ordersPlaceClient,
    optionsClient,
    salesClient,
    stocksClient,
    branchesClient,
    kvpsClient,
    snippetsClient,
    collectionsClient,
    authClient,
    uploadClient,
    xeroxClient,
}