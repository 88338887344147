import revive_payload_client_4sVQNw7RlN from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import gtag_client_R70iZRv6jK from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/plugins/gtag.client.js";
import clickOutside_ZB3Eg0wOVk from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/plugins/clickOutside.ts";
import useBootstrap_client_ouDJvcVteA from "C:/workspace/frontend/rigg/delta-akhtar-stations/akhtar-pub/plugins/useBootstrap.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  gtag_client_R70iZRv6jK,
  clickOutside_ZB3Eg0wOVk,
  useBootstrap_client_ouDJvcVteA
]