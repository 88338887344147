<template>
  <div class="cart-item-container row">
    <div class="image-container col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
      <div class="image">
        <img :src="productImage" alt="" style="width: 80px;">
      </div>
    </div>
      <ul class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style="list-style-type : none;">
        <li>
          <strong style="color: gray; font-size: .85rem;">{{ title }}</strong>
        </li>
        <li style="font-size: 14px; font-weight: 700">
          {{ quantity }} X {{ itemPrice }}
        </li>
      </ul>

    <div class="text col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4" style="display: flex; justify-content: flex-end">
        <strong>{{itemCurrency}} {{ itemSubtotal }}</strong>
    </div>

  </div>
</template>

<script setup>
import {cartItemDto} from "@/composables/cart-details/cartItemDto.js";
import { useCartStore } from "@/stores/cart";
import { useCurrenciesStore } from "@/stores/currencies-store";
const cartStore = useCartStore();
const currenciesStore = useCurrenciesStore();

const props = defineProps({
  item: {
    type: Object,
    default: null,
  },
});

const { 
    id,
    productImage,
    title,
    productAttributes,
    productSize,
    productColor,
    price,
    quantity,
    subtotal,
} = cartItemDto(props.item);

const itemPrice = computed(() => {
    let total = 0;
    if (currenciesStore.country && currenciesStore.country.currency != "BDT" && currenciesStore.country.rate > 0) {
        if (price.value > 0) {
            total = (Number(price.value) / Number(currenciesStore.country.rate)).toFixed(2);
        }
    } else {
        if (price.value > 0) {
            total = (Number(price.value)).toFixed(2);
        }
    }

    return total;
});


const itemSubtotal = computed(() => {
    let total = 0;
    if (currenciesStore.country && currenciesStore.country.currency != "BDT" && currenciesStore.country.rate > 0) {
        if (subtotal.value > 0) {
            total = (Number(subtotal.value) / Number(currenciesStore.country.rate)).toFixed(2);
        }
    } else {
        if (subtotal.value > 0) {
            total = (Number(subtotal.value)).toFixed(2);
        }
    }

    return total;
});

const itemCurrency = computed(() => {
    let currency = "BDT"
    if (currenciesStore.country && currenciesStore.country.currency && currenciesStore.country.currency.length > 0) {
        currency = currenciesStore.country.currency;
    } 

    return currency;
});
</script>
