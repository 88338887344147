<template>
    <div class="navbar-container" style="z-index: 3025;">
        <div class="navbar-top-section px-5 py-2">
            <div class="row">
                <div class="col-4 col-lg-4 d-flex justify-content-center align-items-center">
                    <nav class="d-flex align-items-center me-4 d-lg-none" id="NavBar">
                        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation"
                            @click.prevent="showSearchCart = !showSearchCart">
                            <span class="icon-bar top-bar"></span>
                            <span class="icon-bar middle-bar"></span>
                            <span class="icon-bar bottom-bar"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent"></div>
                    </nav>
                    <SearchBar @hideNav="$emit('hideNav')" />
                </div>

                <div class="col-4 col-lg-4 d-flex justify-content-center align-items-center">
                    <div class="logo-section">
                        <RouterLink to="/" @click.prevent="gotoIndexPage()">
                            <img class="logo-img" src="/logo/logo.png" alt="Logo" />
                        </RouterLink>
                    </div>
                </div>
                <div class="col-4 col-lg-4 d-flex justify-content-end align-items-center">
                    <CartSection />
                </div>
            </div>
        </div>

        <!-- <div class="navbar-middle-section-v2 px-5 py-2" id="navLink"> -->
        <div class="navbar-middle-section-v2 py-2" id="navLink"> <!-- new -->
            <NavbarItems v-if="!showSearchCart || showSearchCart" @gotoIndexPage="gotoIndexPage()" />
            <!-- <NavbarItemsV2 v-if="showSearchCart" @gotoIndexPage="gotoIndexPage()" /> -->
        </div>

        <!-- <CommonsNavTopBarNav :show="showSearchCart" @close="showSearchCart = !showSearchCart" /> -->
    </div>
</template>

<script setup>
import CartSection from "./CartSection.vue";
import NavbarItems from "./NavbarItems.vue";
import NavbarItemsV2 from "./NavbarItemsV2.vue";
import SearchBar from "./SearchBar.vue";

const router = useRouter();
const showSearchCart = ref(false);

const gotoIndexPage = async () => {
  router.push({
      name: "index",
  })
}
</script>

<style lang="scss">
@media screen and (min-width: 0px) and (max-width: 1200px) {
    .navbar-middle-section-v2 {}
}
@media screen and (max-width: 1201px) {
  .navbar-container {
    display: none;
  }
}

.navbar-toggler span {
    display: block;
    background-color: #4f4f4f;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;
}

.navbar-toggler span:nth-child(1) {
    transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(2) {
    opacity: 1;
}

.navbar-toggler span:nth-child(3) {
    transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(1) {
    margin-top: 0.3em;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    transform: translate(15%, 33%) rotate(-45deg);
}
.navbar-toggler span {
  height: 2px;
  width: 20px;
}
</style>
